import styles from './styles.module.css'
import Block2 from "./block2/Block2";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {setTitle} from "../../app/store/slices/page-info-slice";
import {useDispatch, useSelector} from "react-redux";

function OneNews() {
    const language = useSelector((state: any) => state.localLanguage.language)
    const navigate = useNavigate()
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [currentNews, setCurrentNews] = useState(null);

    const params = useParams();

    const { id } = params;

    const dispatch = useDispatch();


    useEffect(() => {
        fetch(`/api/news/${id}`)
            .then(response => {
                if (response.ok) {
                    return response.json()
                }
                throw response;
            })
            .then(data => {
                setCurrentNews(data.news)
                dispatch(setTitle(language === 'ru' ? data.news.headers1 : data.news.headers1EN));
            })
            .catch(error => {
                console.error("Error fetching data: ", error);
                setError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [language]);
    if (loading) return <>Loading...</>
    if (error) return <>Error!</>




    function goToNews(id: any){
        navigate(`/news/${id}`);
    }



    // @ts-ignore
    return <div className={styles.background}>
        {/*<BlackBlock news={news}/>*/}
        <div className={styles.conteinerBody}>
            <Block2 news={currentNews}/>
        </div>
    </div>
}

export default OneNews;
