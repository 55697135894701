import styles from './styles.module.scss';
import React, {useEffect, useState} from "react";
import {MobileNewsItem} from "./news-item/mobile";
import {Link} from "react-router-dom";
import {ApiProvider} from "app/api/api-provider";
import empty from '../../images/empty.png'
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';


export type TNews = {
    topHeader: string;
    secondHeader: string;
    date: string;
    photo: string;
}

export type TMainNews = {
    promoBanner: string;
    topHeader: string;
    secondHeader: string;
    shortContent: string;
    topNewsLinks: TNews[];
}

export interface INewsArgs {
    news: TMainNews;
}

type TOneNews = {
    id: string;
    banner: string;
    headers1: string;
    headers2: string;
    text: string;
    headers1EN: string;
    headers2EN: string;
    textEN: string;
    date: string;
}

export function NewsTable({navi}: any){
    const local = useSelector((state: any) => state.localLanguage.language)
    const [oneNews, setOneNews] = useState<TOneNews>();
    const [data, setData] = useState<TOneNews[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const api = ApiProvider();



    useEffect(() => {
        api.getNewsData({page: 0, count: 20})
            .then(data => {
                if(data.allNews.length < 1) {
                    return setError('No content')
                }
                setData(data.allNews);
                setOneNews(data.allNews[0]);
            })
            .catch(error => {
                console.log('error', error)
                setError(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }, []);

    if (loading || !oneNews) return <>Loading...</>
    if (error) return <FormattedMessage id={'noNews'}/>

    function goToNews(id:string){
        navi(`/news/${id}`);
    }

    const mobileNews = data.slice(0, 4).map((link: any) => {
        return (<MobileNewsItem onClickHandler={() => goToNews(link?.id)}>
            {link.headers1}
        </MobileNewsItem>)
    });

    return <div className={styles.NewsTable}>
        <div className={styles.MainNews}>
            <div className={styles.PromoBanner}>
                <img src={oneNews.banner} alt="Nothing showed"/>
                <div className={styles.gradient}></div>
            </div>
            <div className={styles.TextContent}>
                <div className={styles.TopHeader}>{local === 'ru' ? oneNews.headers1 : oneNews.headers1EN}</div>
                <div className={styles.SecondHeader}>{local === 'ru' ? oneNews.headers2 : oneNews.headers2EN}</div>
                <div className={styles.ShortContent}>{local === 'ru' ? oneNews.text : oneNews.textEN}</div>
            </div>
        </div>
        <div className={styles.NewsList}>
            {data.map(link =>
                <>
                    <div
                        className={styles.NewsLink}
                        onClick={() => goToNews(link.id)}
                    >
                        <div className={styles.TextBlock}>
                            <div className={styles.TopHeader}>{local === 'ru' ? link.headers1 : link.headers1EN}</div>
                            <div className={styles.SecondHeader}>{local === 'ru' ? link.headers2 : link.headers2EN}</div>
                            <div className={styles.Date}>{link.date}</div>
                        </div>
                        <div className={styles.ImageBlock}>
                            <img src={link.banner || empty} alt='nothing showed'/>
                        </div>
                    </div>
                </>)}
            {mobileNews}
            <Link to='news' className={styles.allNewsLink}><FormattedMessage id={'seeAll'}/></Link>
        </div>
    </div>
}