import styles from './styles.module.scss';
import {ITournamenLineArgs} from "./types";
import {LiveStreamSign} from "./ui/live-sign/LiveStreamSign";
import {Link, useNavigate} from "react-router-dom";
export function TournamentLineMobile({
    name,
    live,
    dateOf,
    id,
                               }: ITournamenLineArgs){
    return <Link to={`/tournaments/${id}`} className={styles.TournamentLine}>
        <div className={styles.info}>
            <p className={styles.name}>{name}</p>
            <p className={styles.time}>{dateOf}</p>
        </div>

        <div className={styles.live}>
            {live && <div><LiveStreamSign/></div>}
        </div>
    </Link>
}