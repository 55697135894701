// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_tabs__9xy7f {
  display: flex;
  overflow: auto;
  justify-content: center;
  gap: 42px;
  padding-bottom: 4px;
  padding-top: 7px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.styles_tabs__9xy7f::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/shared/tabs/styles.module.scss","webpack://./src/app/styles/mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,cAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,gBAAA;ECPA,wBAAA;EACA,qBAAA;ADOF;ACLE;EACE,aAAA;ADOJ","sourcesContent":["@use 'app/styles/mixins' as mixins;\n\n.tabs {\n  display: flex;\n  overflow: auto;\n  justify-content: center;\n  gap: 42px;\n  padding-bottom: 4px;\n  padding-top: 7px;\n\n\n  @include mixins.hide-scrollbar;\n}","@mixin hide-scrollbar() {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n\n  &::-webkit-scrollbar {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `styles_tabs__9xy7f`
};
export default ___CSS_LOADER_EXPORT___;
