import styles from './styles.module.scss';
import type { IStatisticsLineProps } from "./types";


export function StatisticsLine({ first, second}: IStatisticsLineProps) {
    return <div className={styles.statisticsLine}>
        <div>
            <div className={styles.firstSide}
                 style={{
                     width: `${first}%`
            }}/>
        </div>
        <div>
            <div className={styles.secondSide}
                 style={{
                     width: `${second}%`
            }}/>
        </div>
    </div>;
}