import { useState } from 'react';
import {EWinnerTeamIndex, ITournamentCardArgs} from "./types";
import styles from './styles.module.scss';
import {TeamList} from "../../entities/ui/TeamList/TeamList";
import {WinSign} from "../../features/WinSign/WinSign";
import {ScoresList} from "../../entities/ui/scores-list/ScoresList";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMatchBeat } from "../../app/store/slices/match-beats-slice";
import classNames from "classnames";
import useDatetime from "./useDatetime";
import useTournamentGridData from "widgets/tournament-grid/UseTournamentGridData";
import changeTime from '../../images/time-change.png'

import {
    ELLIMINATION_TOURNAMENT_CARD,
    TOP_GRID_CARD,
    BOTTOM_GRID_CARD,
    FOR_SEVENTH_PLACE_CARD,
    FOR_FIFTH_PACE_CARD,
    HEMIFINAL,
    FINAL,
} from 'shared/constants'
import { FormattedMessage } from 'react-intl';

export function TournamentCard({
                                   className,
                                   topTeam,
                                   bottomTeam,
                                   topScores,
                                   bottomScores,
                                   winnerTeamIndex,
                                   inGrid,
                                   match,
                                   fullwidth = false,
                                   datetime = '',
                                   matchId = -1,
                                   tourId = -1,
                                   cardType = ELLIMINATION_TOURNAMENT_CARD,
                                }: ITournamentCardArgs){

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { date, time } = useDatetime(datetime);
    const { tournamentId } = useTournamentGridData()

    const [awaitingAlert, setAwaitingAlert] = useState(false);
    const [changeStartMatchStatus, setChangeStartMatchStatus] = useState(false);

    const goToMatch = () => {
            if(match.topScores.length === 0 || match.bottomScores.length === 0){
              setAwaitingAlert(true)
              setTimeout(() => {
                setAwaitingAlert(false)
              }, 2000);
              return
            }
            navigate(`/match/${match.systemId}`)
    }


    return <div className={classNames(styles.TournamentCard, {
        [styles.fullwidth]: fullwidth,
        [styles.awaitingAlert]: awaitingAlert,
    })}>

        {match.online && <div className={styles.OnlineIndicator}/>}

        {time && date && <div className={styles.datetimeWrapper}>
            <div><label><FormattedMessage id={'date'}/>: </label>{ date } </div>
            <div><label><FormattedMessage id={'start'}/>: </label>{ time }</div>
            {match.changeStartMatch && <img 
                className={styles.ChangeStartMatch}
                alt={''}
                src={changeTime}
                onMouseOver={() => setChangeStartMatchStatus(true)}
                onMouseOut={() => setChangeStartMatchStatus(false)}
                onTouchStart={() => setChangeStartMatchStatus(!changeStartMatchStatus)}
            />}
            {<div className={changeStartMatchStatus ? styles.ChangeStartMatchText + ' ' + styles.ChangeStartMatchTextActive : styles.ChangeStartMatchText}>
                {match.changeStartMatch}
            </div>}
        </div>}

        {!className && inGrid && <div className={styles.LeftConnector} >
            <div className={styles.LeftConnectorLine} />
        </div> }
        <div className={styles.TopLine}  onClick={goToMatch}>
            {topTeam.players.length !== 0 && <>
                <TeamList team={topTeam}/>
                <WinSign showState={winnerTeamIndex === EWinnerTeamIndex.topTeamIsWin}/>
                {match.team1Addition.includes("ret") && <div className={styles.Ret}>Ret</div>}
                <div className={styles.StatusPlayer}>{(match.team1Addition[0] === 'cup' || match.team1Addition[0] === 'ret') ? '' : match.team1Addition[0]}</div>
                <ScoresList scores={topScores} />
            </>}
            {tourId === 0 && topTeam.players.length === 0 && bottomTeam.players.length === 1 && <div className={styles.Bye}>Bye</div>}
        </div>
        <div className={styles.BottomLine}  onClick={goToMatch}>
            {bottomTeam.players.length !== 0 && <>
                <TeamList team={bottomTeam}/>
                <WinSign showState={winnerTeamIndex === EWinnerTeamIndex.bottomTeamIsWin}/>
                {match.team2Addition.includes("ret") && <div className={styles.Ret}>Ret</div>}
                <div className={styles.StatusPlayer}>{(match.team2Addition[0] === 'cup' || match.team2Addition[0] === 'ret') ? '' : match.team2Addition[0]}</div>
                <ScoresList scores={bottomScores} />
            </>}
            {tourId === 0 && bottomTeam.players.length === 0 && topTeam.players.length === 1 && <div className={styles.Bye}>Bye</div>}
        </div>

    </div>

}
