import axios from "axios";
import {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {EWinnerTeamIndex} from "../tournament-card/types";
import styles from "./styles.module.scss";
import {useDispatch} from "react-redux";
import {setTitle} from "../../app/store/slices/page-info-slice";
import { MATCH_STATUS_PLAYED } from "shared/constants";
import { useIntl } from "react-intl";

const API_URL = '/api/tournament'

const TournamentProvider = function(){
    return {
        take: async (id: string) => {
            const res = await axios.get(API_URL + '/' + id)

            return res.data.tournament;
        }
    }
}

const TOURNAMENT_UPDATE_INTERVAL_TIME = 2000

let tournamentUpdateInterval: any;

const useTournamentGridData = () => {
    const intl = useIntl()

    const tournamentProvider = TournamentProvider();

    const [tournament, setTournament] = useState<any>();

    const [tournamentName, setTournamentName] = useState('');

    const params = useParams();


    const { id: tournamentId } = params;

    const [tournamentGridHeight, setTournamentGridHeight] = useState(0);

    const [matchesOnline, setMatchesOnline] = useState([]);

    const [onlineMatchesAside, setOnlineMatchesAside] = useState<boolean>(false);

    const [isEllimination, setIsEllimination] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
      console.log('tournament was updated')
    }, [tournament])


    const takeWinnerId = (match: any) => {
        if(match.team1Addition.includes('cup')){
            return EWinnerTeamIndex.topTeamIsWin;
        }else if(match.team2Addition.includes('cup')){
            return EWinnerTeamIndex.bottomTeamIsWin;
        }
        return EWinnerTeamIndex.noOneTeamIsWin;
    }


    const matchConverter = (match: any) => {
        const { online, matchbeats, datetime, team1Addition, team2Addition, changeStartMatch } = match;

        return {
            systemId: match._id,
            team1Addition: team1Addition,
            team2Addition: team2Addition,
            status: match.status,
            online: match.status === MATCH_STATUS_PLAYED,
            matchbeats,
            datetime,
            winnerTeamIndex: takeWinnerId(match),
            topTeam: {
                teamId: '1',
                players: match.team1Names.map((p: any) => {
                    return {
                        fullName: p.name,
                        country: p.country,
                        id: p.id,
                    }
                })
            },
            bottomTeam: {
                teamId: '2',
                players: match.team2Names.map((p: any) => {
                    return {
                        fullName: p.name,
                        country: p.country,
                        id: p.id,
                    }
                })
            },
            topScores: match.team1Score?.map((score: any) => {
                return {
                    score: score.score,
                    tie: score.tiebreak,
                }
            }) || [],
            bottomScores: match.team2Score?.map((score: any) => {
                return {
                    score: score.score,
                    tie: score.tiebreak,
                }
            }) || [],
            changeStartMatch: changeStartMatch
        }
    }


    const buildTournamentName = (divider: number) => {
        if(divider === 1){
            return intl.formatMessage({id: "theFinal"})
        }else if(divider === 2){
            return intl.formatMessage({id: "semi-final"})
        }
        return `1/${divider}`
    }

    const tourConverter = (tour: any, tid: number) => {
        return {
            name: buildTournamentName(tour.length),
            matches: tour.map(matchConverter)
        }
    }

    const convertBackendDataToInternalObject = (tours: any) => {

        return tours.map(tourConverter)
    }

    useEffect(() => {
        const tgh = document.querySelector(`.${styles.TournamentGrid}`)?.clientHeight;
        setTournamentGridHeight(tgh || 0);
    }, [tournament])

    useEffect(() => {


        const takeTournamentGrid = async () => {
          if(!tournamentId){
              return;
          }
            const newTournament =  await tournamentProvider.take(tournamentId)

            setIsEllimination(newTournament.isEllimination)

            const tempTournamentName = newTournament.name || '';

            dispatch(setTitle(tempTournamentName))

            setTournamentName(tempTournamentName);

            if(newTournament.isEllimination){
                const internalTournament = convertBackendDataToInternalObject(newTournament.tours);

                setTournament(internalTournament);
                
                const newMatchesOnline: any = []

                internalTournament.forEach((tour: any, tourId: number) => {
                  tour.matches.forEach((match: any, matchId: number) => {
                    if(match.status === MATCH_STATUS_PLAYED){
                      newMatchesOnline.push({
                        ...match,
                        matchId,
                        tourId
                      })
                    }
                  })
                })

                // const matchesOnlineList = internalTournament?.map(({matches}: any) => matches).flat(Infinity).filter((m: any) => m.status === MATCH_STATUS_PLAYED);


                setMatchesOnline(newMatchesOnline);

                setOnlineMatchesAside(newMatchesOnline.length > 0);
            }else{
                const upperGrid = convertBackendDataToInternalObject(newTournament.upperGrid)
                const bottomGrid = newTournament.bottomGrid.map((tour: any) => tour.map(matchConverter))

                const localTournament = {
                    upperGrid,
                    bottomGrid,
                    forSeventPlace: matchConverter(newTournament.forSeventPlace),
                    forFifthPlace: matchConverter(newTournament.forFifthPlace),
                    hemifinal: matchConverter(newTournament.hemiFinal),
                    final: matchConverter(newTournament.final),
                    isEllimination: newTournament.isEllimination,
                }
                
                setTournament(localTournament)

                const toOnlineTestMatchesArray = [
                  upperGrid.map((t: any) => t.matches),
                  bottomGrid,
                  localTournament.forSeventPlace,
                  localTournament.forFifthPlace,
                  localTournament.hemifinal,
                  localTournament.final,
                ].flat(Infinity)

                const matchesOnlineList: any = toOnlineTestMatchesArray.filter((m: any) => {
                    return m.status === MATCH_STATUS_PLAYED
                });

                setMatchesOnline(matchesOnlineList)
            }
        }


        if(!tournamentUpdateInterval){
          takeTournamentGrid()
        }

        if(tournamentUpdateInterval){
          clearInterval(tournamentUpdateInterval)
        }

        tournamentUpdateInterval = setInterval(takeTournamentGrid, TOURNAMENT_UPDATE_INTERVAL_TIME)

        return () => {
            clearInterval(tournamentUpdateInterval)
        }

    }, [tournamentId, setTournament]);

    return {
        tournament,
        tournamentName,
        tournamentId,
        tournamentGridHeight,
        matchesOnline,
        onlineMatchesAside,
        setOnlineMatchesAside,
        isEllimination,
    }
}

export default useTournamentGridData
