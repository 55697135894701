import {Header} from "../../shared/header";
import {BreadCrumbs} from "../../entities/ui/breadcrumbs/BreadCrumbs";
import styles from "../main-page/styles.module.scss";
import {Divider} from "../../entities/ui/divider/Divider";
import {TournamentsTable} from "../../widgets/tournaments-table";
import matchImage from "../../asserts/e601cb.jpg";
import {Footer} from "../../widgets/footer/footer";
import React, {MouseEventHandler, SyntheticEvent, createRef, useCallback, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {AceBall, BreakPointBall, DoubleFaultsBall, Game} from "./UI/Game/Game";
import {TMatch} from "../../app/store/slices/match-beats-slice";
import {BreakPoint, DoubleFaults, Aces} from './svg'
import Navigation from "../../features/navigation/navigation";
import {Simulate} from "react-dom/test-utils";
import classNames from 'classnames';
import blur = Simulate.blur;
import {MatchBeatsWidget} from "./UI/MatchBeatsWidget/MatchBeatsWidget";

const GAME = 100;
const AD = 200;
const BREAKPOINT = 300;
const ACE = 1;
const DOUBLE_FAULTS = 2;

// const tournamentData = {
//     mainName: 'Мастерсс. Россия',
//     tours: [
//
//     ]
// }

export function HoldSign(){
    return <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNS4zODIiIGhlaWdodD0iMTguNjQ3Ij48ZyBmaWxsPSIjNDY5ZmVlIj48cGF0aCBkPSJNMTMuMDAyIDguMTI4YzEuMzE0LTIuMjc2LjgyNC01LjAyOC0xLjA4LTYuMTI4cy00LjUzNC0uMTQ4LTUuODQ5IDIuMTI4YTUuMSA1LjEgMCAwMC0uNTAzIDQuMTM2Yy0uMDAyLjAwNCAwIC4wMTQuMDAzLjAyMWE0LjMzNyA0LjMzNyAwIDAxLS4wOTggMi45OC4zLjMgMCAwMC0uMDM4LjEybC0uMDA1LjAwOC0yLjYxNCA0LjUyOGEuMzA4LjMwOCAwIDAwLjExMy40MmwxLjA2NS42MTVhLjMwOC4zMDggMCAwMC40Mi0uMTEybDIuNjE1LTQuNTI4YS4yOTMuMjkzIDAgMDAuMDIyLS4wNjQuMjkzLjI5MyAwIDAwLjA0NC0uMDUgNC4zNTggNC4zNTggMCAwMTIuNTc0LTEuNTU4LjMuMyAwIDAwLjA3LS4wM0E1LjExNiA1LjExNiAwIDAwMTMgOC4xMjl6TTYuNjEgNC40MzhjMS4xNC0xLjk4NCAzLjM4Ny0yLjgzOSA1LjAwNC0xLjkwNXMxLjk5NiAzLjMwNC44NTIgNS4yODYtMy4zOTEgMi44MzctNS4wMDQgMS45MDVhMi45NDcgMi45NDcgMCAwMS0xLjI4My0xLjU2MWwtLjAyMi0uMDY5YTQuNDg0IDQuNDg0IDAgMDEuNDUtMy42NTh6bS4wNTQgNy4zMDZsLS41NzItLjMzYTMuNzI2IDMuNzI2IDAgMDAuMzI0LTEuNzM4IDMuMjYxIDMuMjYxIDAgMDAxLjU5OC45MjIgMy42NjYgMy42NjYgMCAwMC0xLjM1NSAxLjE0OHoiLz48cGF0aCBkPSJNNS44MSAxMS45NjZsLjUzMy4zMDctMi4zMDcgMy45OTUtLjUzMi0uMzA3eiIvPjwvZz48L3N2Zz4=" />
}

export function GridPage() {

    
    const beats: any = useSelector((state: RootState) => state.matchBeats.data) as any[];

    return <>
        <Header/>
        <Navigation/>
        <div className={'Total'}>
            {/* <BreadCrumbs/> */}
            <div className={'PercentageContent'}>
                {(beats as any).map((beat: any) => <MatchBeatsWidget beats={beat} />)}
                <div className={styles.VideoFrame}>
                    <video src="rtsp://wowzaec2demo.streamlock.net/vod/mp4:BigBuckBunny_115k.mp4"/>
                    {/* <iframe width="1280" height="100%" src="https://www.youtube.com/embed/RRGlEkmq6xc" title="Novak Djokovic | Serve Practice [2023] [4k 60fps]" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe> */}
                </div>
            </div>
            {/* <img className={'background-image-mesh'} style={{filter: "none"}} src={matchImage} alt={'Nothing showed'} /> */}
        </div>

        <Footer/>
    </>
}
