import styles from './styles.module.scss';
import  {StatisticsLabel } from "./statisticsLabel";
import type {IStatisticsUnitProps} from "./types";
import {StatisticsLine} from "./statisticsLine";


export function StatisticsUnit({ name, firstSide, secondSide}: IStatisticsUnitProps) {
    return <div className={styles.statisticsUnitWrapper}>
        <div className={styles.statisticsUnitWrapper__labels}>
            <StatisticsLabel>{firstSide.label}</StatisticsLabel>
            <StatisticsLabel>{name}</StatisticsLabel>
            <StatisticsLabel>{secondSide.label}</StatisticsLabel>
        </div>
        <StatisticsLine first={firstSide.percent} second={secondSide.percent}></StatisticsLine>
    </div>
}