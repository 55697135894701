import styles from "./styles.module.scss";
import React, {useState} from "react";
import {TournamentCard} from "../../tournament-card/TournamentCard";
import { useIntl } from "react-intl";

interface IMobileTournamentProps {
    tournament: any;
    tournamentName: string;
    isSchedule?: boolean;
}


export const MobileTournament = ({tournament, tournamentName, isSchedule}: IMobileTournamentProps) => {
    const intl = useIntl()
    const [activeTour, setActiveTour] = useState(0);

    const changeTour = (index: number) => {
        setActiveTour(index);
    }

    return <div className={styles.mobileTournamentWrapper}>
        <div className={styles.tours}>
            {tournament.map((tour: any, tid: number) => {
                const matchesLen = tour.matches.length;

                const hasMatches = !!tour.matches.find((match: any) => {
                    return match?.bottomTeam.players?.length > 0 || match?.topTeam.players?.length > 0;
                });

                const name = matchesLen === 1? intl.formatMessage({id: "theFinal"}) : `1/${matchesLen}`;

                return (<button disabled={!hasMatches} className={tid === activeTour ? styles.active: ''} key={tid} onClick={() => changeTour(tid)}>{name}</button>)
            })}
        </div>

        <div className={styles.tournamentName}>{tournamentName}</div>

        <div className={styles.matches}>
            {tournament[activeTour]?.matches.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    matchId={mid}
                    tourId={activeTour}
                    datetime={ match.datetime }
                />;
            })}
        </div>





    </div>
}
