import {ETournamentType, ITableTabArgs} from "./types";
import classNames from "classnames";
import styles from './styles.module.scss';
import { FormattedMessage } from "react-intl";

export function TableTab({
        name,
        onClick,
        className,
        tournamentType,
        isActive = false
                         }: ITableTabArgs){
    const style = classNames([
        className,
        styles.TableTab,
    ],
        {
            [styles.World]: tournamentType === ETournamentType.world,
            [styles.Masters]: tournamentType === ETournamentType.masters,
            [styles.Wheelchair]: tournamentType === ETournamentType.wheelchair,
            [styles.Beach]: tournamentType === ETournamentType.beach,
            [styles.IsActive]: isActive,
        }
    );

    return <div onClick={onClick}  className={style}>
        <div>
            <FormattedMessage id={className}/>
        </div>
        {isActive && <div className={styles.hideBorder}></div>}
    </div>
}