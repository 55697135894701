import { configureStore } from "@reduxjs/toolkit";
import matchBeatsReducer from './slices/match-beats-slice';
import pageInfoReducer from './slices/page-info-slice';
import localLanguageSlice from "./slices/locale-language-slice";
export const store = configureStore({
    reducer: {
        matchBeats: matchBeatsReducer,
        pageInfo: pageInfoReducer,
        localLanguage: localLanguageSlice
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch