import { createSlice } from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit";


const GAME = 100;
const AD = 200;
const BREAKPOINT = 300;
const ACE = 1;
const DOUBLE_FAULTS = 2;

export interface TOpponent {
    name: string;
}

export type TMatchScore = [number, number, boolean, boolean] | [number, number, boolean, boolean, number, number]
export type TMatchResult = {
    setScore: [number, number];
    tie: [TMatchScore, TMatchScore];
    winner: number;
    holds: number;
    time: string;
}

export interface TMatch {
    holds: number;
    scores: TMatchScore[];
    results: TMatchResult;
    isTieBreak: boolean
}
export interface MatchBeatsSlice {
    data: any;
}


const initialState: MatchBeatsSlice = {
    data: [],
}

export const matchBeatsSlice = createSlice({
    name: 'matchbeats',
    initialState,
    reducers: {
        setMatchBeat: (state: any, action: any) => {
            return {
                // ...state,
                
                data: [...action.payload],
            }
        },
    } // todo
});

export const {
    setMatchBeat,
} = matchBeatsSlice.actions;

export default matchBeatsSlice.reducer;
