import styles from "./styles.module.scss";
import React, {useState} from "react";
import {TournamentCard} from "../../tournament-card/TournamentCard";
import { BOTTOM_GRID_CARD, FOR_FIFTH_PACE_CARD, FOR_SEVENTH_PLACE_CARD, HEMIFINAL, TOP_GRID_CARD, FINAL } from "shared/constants";
import { FormattedMessage, useIntl } from "react-intl";

interface IMobileTournamentProps {
    tournament: any;
    tournamentName: string;
    isSchedule?: boolean;
}


export const MainMobileTournament = ({tournament, tournamentName, isSchedule = true}: IMobileTournamentProps) => {
    const intl = useIntl()
    const [activeTour, setActiveTour] = useState(0);

    const changeTour = (index: number) => {
        setActiveTour(index);
    }

    return <div className={styles.mobileTournamentWrapper}>
        <div className={styles.tours}>
            {[
                useIntl().formatMessage({id: "first"}),
                useIntl().formatMessage({id: "second"}),
                useIntl().formatMessage({id: "third"}),
                useIntl().formatMessage({id: "semi-final"}),
                useIntl().formatMessage({id: "theFinal"})
            ].map((name: string, tid: number) => {
                return (<button className={tid === activeTour ? styles.active: ''} key={tid} onClick={() => changeTour(tid)}>{name}</button>)
            })}
        </div>

        <div className={styles.tournamentName}>{tournamentName}</div>


        

        <div className={styles.matches}>

            {activeTour === 0 && <FormattedMessage id={'upperMesh'}/>}
            {activeTour === 0 && tournament.upperGrid[0]?.matches.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={TOP_GRID_CARD}
                    tourId={activeTour}
                />;
            })}

            {activeTour === 0 && <FormattedMessage id={'bottomMesh'}/>}
            {activeTour === 0 && tournament.bottomGrid[0]?.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={BOTTOM_GRID_CARD}
                    tourId={activeTour}
                />;
            })}
            {activeTour === 0 && <FormattedMessage id={'for7thPlace'}/>}
            {activeTour === 0 && [tournament.forSeventPlace]?.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={FOR_SEVENTH_PLACE_CARD}
                />;
            })}

            {activeTour === 1 && <FormattedMessage id={'upperMesh'}/>}
            {activeTour === 1 && tournament.upperGrid[1]?.matches.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={TOP_GRID_CARD}
                />;
            })}

            {activeTour === 1 && <FormattedMessage id={'bottomMesh'}/>}
            {activeTour === 1 && tournament.bottomGrid[1]?.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={BOTTOM_GRID_CARD}
                />;
            })}
            {activeTour === 1 && <FormattedMessage id={'for5thPlace'}/>}
            {activeTour === 1 && [tournament.forFifthPlace]?.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={FOR_FIFTH_PACE_CARD}
                />;
            })}

            {activeTour === 2 && <p>1/2<FormattedMessage id={'finals'}/></p>}
            {activeTour === 2 && tournament.upperGrid[2]?.matches.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={TOP_GRID_CARD}
                />;
            })}
            {activeTour === 2 && tournament.bottomGrid[2]?.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={BOTTOM_GRID_CARD}
                />;
            })}

            {activeTour === 3 && <FormattedMessage id={'semi-final'}/>}
            {activeTour === 3 && [tournament.hemifinal]?.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={HEMIFINAL}
                />;
            })}
            {activeTour === 4 && <FormattedMessage id={'theFinal'}/>}
            {activeTour === 4 && [tournament.final]?.map((match: any, mid: any) => {
                return <TournamentCard
                    key={mid + activeTour}
                    className='start'
                    topTeam={match.topTeam}
                    bottomTeam={match.bottomTeam}
                    topScores={match.topScores}
                    bottomScores={match.bottomScores}
                    winnerTeamIndex={match.winnerTeamIndex}
                    inGrid={true}
                    match={match}
                    datetime={ isSchedule ? match.datetime : '' }
                    cardType={FINAL}
                />;
            })}
        </div>





    </div>
}
