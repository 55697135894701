import styles from './styles.module.scss';
import {tournamentTableInputData, menContent as mc} from "./mock";
import {TableTab} from "../../features/tournament-table/table-tab/TableTab";
import {createRef, useCallback, useEffect, useState} from "react";
import {TableHeader} from "../../features/tournament-table/table-header/TableHeader";
import {TournamentLine} from "features/tournament-table/tournament-line";
import {TableFooter} from "features/tournament-table/table-footer/TableFooter";
import axios from 'axios';
import {TournamentLineMobile} from "features/tournament-table/tournament-mobile";
import {pageLimit} from "./consts";
import useTournamentData from "./useTournamentData";
import { FormattedMessage } from 'react-intl';

export function TournamentsTable(props: {live?: string | object}){
    const {
        onTabClick,
        changePage,
        tournament,
        tournamentType,
        hasCurrentType,
        isVisibleShowMore
    } = useTournamentData(props.live);

    const [thisMobile, setThisMobile] = useState(false);
    const [isActiveGrub, setIsActiveGrub] = useState(false);
    const [offset, setOffset] = useState(0);
    const [clickPosition, setClickPosition] = useState(-1);

    const activateGrab = useCallback((event: any) => {
        setIsActiveGrub(true)
        setClickPosition(event.clientX)
    }, []);

    const activateGrabMobile = useCallback((event: any) => {
        setThisMobile(true)
        setIsActiveGrub(true)
        setClickPosition(event.changedTouches[0].clientX)
    }, []);

    const deactivateGrab = useCallback(() => {
        setThisMobile(false)
        setIsActiveGrub(false)
        setClickPosition(-1);
    }, []);

    const widthWindow = createRef<HTMLDivElement>();
    const widthBlock = createRef<HTMLDivElement>();

    const doGrab = useCallback((event: any) => {
        if(!widthBlock.current || !widthWindow.current){
            return;
        }
        if (widthBlock.current.offsetWidth > widthWindow.current.offsetWidth){
            return;
        }
        if(!isActiveGrub){
            return;
        }
        let newOffset = offset - (clickPosition - (thisMobile?event.changedTouches[0].clientX:event.clientX));
        if(newOffset > 0){
            newOffset = 0
        }
        if(newOffset < (widthBlock.current.offsetWidth - widthWindow.current.offsetWidth)){
            newOffset = widthBlock.current.offsetWidth - widthWindow.current.offsetWidth
        }
        setOffset(newOffset)
        setClickPosition(thisMobile?event.changedTouches[0].clientX:event.clientX)
    }, [clickPosition]);

    const tournamentForLines = tournament.data.filter((row: any) => props.live === "complete" ? row.liveType === props.live : row.liveType !== "complete")

    const lines = tournamentForLines.map((row: any) => {
        return (<>
            <TournamentLine
                id={row.id}
                country={row.country}
                courtType={row.coverage}
                tournamentId={row.id}
                dateOf={row.date}
                startDate={row.startDate}
                completeDate={row.completeDate}
                name={row.name}
                live={row.live}/>
            <TournamentLineMobile
                id={row.id}
                country={row.country}
                courtType={row.coverage}
                tournamentId={row.id}
                dateOf={row.date}
                // startDate={row.startDate}
                // completeDate={row.completeDate}
                name={row.name}
                live={row.live}/>
        </>)}) || null;



    return <div className={styles.TournamentBlock}>
        {/* <div className={styles.TopGradient} />
        <div className={styles.BottomGradient} /> */}
        <div className={styles.TableTopHeader}>
            <h1>
                {props.live === "complete" ? <FormattedMessage id={'completedTournaments'}/>: <FormattedMessage id={'tournaments'}/>}:
            </h1>
        </div>
        <div className={styles.ContainerTabBar} ref={widthBlock}>
            <div
                onMouseDown={activateGrab}
                onMouseUp={deactivateGrab}
                onMouseMove={doGrab}
                onMouseLeave={deactivateGrab}

                onTouchStart={activateGrabMobile}
                onTouchEnd ={deactivateGrab}
                onTouchMove ={doGrab}
                onTouchCancel ={deactivateGrab}

                className={styles.TabBar}
                ref={widthWindow}
                style={{transform: `translate(${offset}px, 0)`}}
            >
                {tournamentTableInputData.types.map(type =>
                    <TableTab
                        key={type.type}
                        className={type.type}
                        name={type.name}
                        onClick={() => onTabClick(type.type, props.live)}
                        tournamentType={type.tournamentType}
                        isActive={tournamentType === type.type}
                    />
                )}
            </div>
        </div>
        
        <div className={styles.TournamentTable}>
            {tournamentForLines.length === 0 && <div className={styles.noData}>
                    <FormattedMessage id={'noTournaments'}/>
            </div>}
            {lines}

            {isVisibleShowMore && <TableFooter onClick={() => changePage(props.live ? props.live : '')}/>}
        </div>
    </div>
}
