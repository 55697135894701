import React, { useCallback, useEffect, useState } from "react";
import style from "./style.module.scss"
import { apiProvider } from "app/api/api-provider";

type TFile = {
    id: string,
    name: string,
    type: string
}

export const DocumentsPage = (): JSX.Element => {
    const [files, setFiles] = useState<TFile[]>([])

    const getFiles = useCallback(async () => {
        const res = await apiProvider.getFilesData()
        if (!res || !res.fileStores) {
            return
        }
        console.log(res)
        setFiles(res.fileStores)
    }, [setFiles, apiProvider])

    const getFile = async (id: string, name: string) => {
        try {
            // Получаем PDF по ID
            const blob = await apiProvider.getFileData(id);
            
            // Проверяем, что получен корректный Blob
            if (!(blob instanceof Blob)) {
                console.error('Некорректный тип данных');
                return;
            }

            const pdfBlob = blob.slice(0, blob.size, 'application/pdf');
        
            // Проверка MIME-типа файла
            if (pdfBlob.type !== 'application/pdf') {
                console.warn('Файл не является PDF');
                return;
            }
            
    
            // Создаем URL для PDF
            const pdfUrl = URL.createObjectURL(pdfBlob);
    
            // Кроссплатформенный метод открытия PDF
            const openPdf = () => {
                // Для мобильных устройств используем window.open
                const newWindow = window.open(pdfUrl, '_blank');
                
                // Дополнительная проверка для iOS/Android
                if (newWindow) {
                    newWindow.focus();
                } else {
                    // Если всплывающее окно заблокировано
                    window.location.href = pdfUrl;
                }
            }
    
            // Открываем PDF
            openPdf();
    
            // Освобождаем ресурсы
            setTimeout(() => URL.revokeObjectURL(pdfUrl), 100);
    
        } catch (error) {
            console.error('Ошибка при открытии PDF:', error);
        }
    }

    useEffect(() => {
        getFiles()
    },[])

    return (
        <div className={style.All}>
            <div className={style.docs}>
                {files.map((file: TFile) => 
                    <div className={style.file}>
                        <svg onClick={() => getFile(file.id, `${file.name}.${file.type}`)} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="SaveAltIcon">
                            <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7zm-6 .67 2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"></path>
                        </svg>
                        <div>{file.name}</div>
                    </div>
                )}
            </div>
        </div>
    )
}