// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_All__qKsLE {
  border-top: 1px dashed #297FB0;
  background-color: rgb(230, 230, 230);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.style_docs__pkxmx {
  width: 80%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;
  padding-top: 20px;
  padding-left: 20px;
}

.style_file__fN1uf {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: #004C77;
  width: max-content;
  height: 40px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
}
.style_file__fN1uf > svg {
  width: 30px;
  height: 30px;
  border: 0.5px solid #004C77;
  border-radius: 3px;
  fill: #004C77;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/documents-page/style.module.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,oCAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;EACA,YAAA;EACA,uBAAA;EAEA,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;EAEA,iBAAA;EACA,kBAAA;AADJ;;AAIA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AADJ;AAGI;EACI,WAAA;EACA,YAAA;EACA,2BAAA;EACA,kBAAA;EACA,aAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;AADR","sourcesContent":[".All {\n    border-top: 1px dashed #297FB0;\n    background-color: rgb(230, 230, 230);\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.docs {\n    width: 80%;\n    height: calc(100%);\n    background-color: white;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: start;\n    gap: 10px;\n\n    padding-top: 20px;\n    padding-left: 20px;\n}\n\n.file {\n    position: relative;\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    align-items: center;\n    color: #004C77;\n    width: max-content;\n    height: 40px;\n    font-family: Montserrat;\n    font-size: 16px;\n    font-weight: 700;\n\n    >svg {\n        width: 30px;\n        height: 30px;\n        border: 0.5px solid #004C77;\n        border-radius: 3px;\n        fill: #004C77;\n        user-select: none;\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"All": `style_All__qKsLE`,
	"docs": `style_docs__pkxmx`,
	"file": `style_file__fN1uf`
};
export default ___CSS_LOADER_EXPORT___;
