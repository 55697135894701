import { createRef, useEffect, useState } from "react";
import {StatsWidget} from "widgets/statsWidget/statsWidget";
import {mockWidget} from "shared/mockStat";
import styles from './styles.module.scss';
import {VideoPlayer} from "widgets/videoPlayer/videoPlayer";
import { useParams } from "react-router-dom";
import { ApiProvider } from "app/api/api-provider";
import { MatchBeatsWidget } from "pages/grid-page/UI/MatchBeatsWidget/MatchBeatsWidget";
import { EMatchStatises } from "shared/constants";
import { TPlayer } from "widgets/current-matches/CurrentMatches";
import { escape } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ButtonMatshSelect } from "widgets/buttons/buttonMatchSelect";
import { ModeMatch } from "./modeWidgets/modeMatch";
import { ModePlayer } from "./modeWidgets/modePlayers";
import { TStatistics } from "app/api/types";


const MATCH_DATA_UPDATE_INTERVAL_TIME = 5000

export const MatchPage = () => {
    const intl = useIntl()

    const { systemMatchId } = useParams()

    const [ matchData, setMatchData ] = useState<any>()
    const [ modeStat, setModeStat ] = useState<number>(0)
    const [ stat, setStat ] = useState<any>()
    const [ clickNavigateMatch, setClickNavigateMatch] = useState<string>('')

    const scrollTrigger = createRef()

    const navigate = useNavigate()

    const setStatistic = (statistics: TStatistics) => {
        setStat(statDataAdapter(statistics))
    }

    useEffect(() => {
        if (modeStat !== 0) {
            return
        }
        setStatistic(matchData?.statistics)
    },[matchData, modeStat])

    useEffect(() => {
            const matchDataUpdateProcedure = async () => {
                if(!systemMatchId){
		                /**
		                * @todo: Make match loading when systemMatchId is nothing
		                */
		                return
                }
                const apiProvider = ApiProvider()
                const matchData = await apiProvider.takeMatchById(systemMatchId)
                if(!matchData){
                    navigate('/')                  
                    console.error('match data is empty', matchData)
                    return
                }
                setMatchData(matchData.match)
            }

            matchDataUpdateProcedure()
            const matchDataUpdateInterval = setInterval(matchDataUpdateProcedure, MATCH_DATA_UPDATE_INTERVAL_TIME)
            return () => {
                clearInterval(matchDataUpdateInterval)
            }
    }, [systemMatchId])

    const playerNamesConcatenation = (team: TPlayer[]) => team.map((player: any) => player.name).join(', ')

    const statisticsHeadersTranslator = (key: string) => {
        const dict: any = {
            aces: intl.formatMessage({id: 'aces'}),
            doubleFaults: intl.formatMessage({id: 'doubleFaults'}),
            breakPoints: intl.formatMessage({id: 'breakPoints'}),
            maxForwardWin: intl.formatMessage({id: 'maxForwardWin'}),
            commonPointsByMatch: intl.formatMessage({id: 'commonPointsByMatch'}),
            countOfWinGame: intl.formatMessage({id: 'countOfWinGame'}),
            onSelfServePointsWinScores: intl.formatMessage({id: 'onSelfServePointsWinScores'}),
            onOtherServePointsWinScores: intl.formatMessage({id: 'onOtherServePointsWinScores'}),
            onSelfServeGamesWin: intl.formatMessage({id: 'onSelfServeGamesWin'}),
        }
        return dict[key]
    }

    const sumTernaryArray = (a: number[], b: number[]) => 
            ([a[0] + b[0], a[1] + b[1], a[2] + b[2]])


    const commonStat = (stat: any) => stat?.reduce((acc: any, curr: any) => ({
        aces: sumTernaryArray(acc.aces, curr.aces),
        doubleFaults: sumTernaryArray(acc.doubleFaults, curr.doubleFaults),
        breakPoints: sumTernaryArray(acc.breakPoints, curr.breakPoints),
        maxForwardWin: sumTernaryArray(acc.maxForwardWin, curr.maxForwardWin),
        commonPointsByMatch: sumTernaryArray(acc.commonPointsByMatch, curr.commonPointsByMatch),
        countOfWinGame: sumTernaryArray(acc.countOfWinGame, curr.countOfWinGame),
        onSelfServePointsWinScores: sumTernaryArray(acc.onSelfServePointsWinScores, curr.onSelfServePointsWinScores),
        onOtherServePointsWinScores: sumTernaryArray(acc.onOtherServePointsWinScores, curr.onOtherServePointsWinScores),
        onSelfServeGamesWin: sumTernaryArray(acc.onSelfServeGamesWin, curr.onSelfServeGamesWin),
    }), {
      aces: [0, 0, 0],
      doubleFaults: [0, 0, 0],
      breakPoints: [0, 0, 0],
      maxForwardWin: [0, 0, 0],
      commonPointsByMatch: [0, 0, 0],
      countOfWinGame: [0, 0, 0],
      onSelfServePointsWinScores: [0, 0, 0],
      onOtherServePointsWinScores: [0, 0, 0],
      onSelfServeGamesWin: [0, 0, 0],
      })

    const serverDataToTabs = (stat: any) => stat.map((eSet: any) => {
        const keys = Object.keys(eSet)
        return {
            name: eSet.setName,
            stats: keys.map((key: string) => {

                const [a1, a2, a3] = eSet[key]
                const leftPercent = (a1 / a3) * 100
                const rightPercent = (a2 / a3) * 100

                const label1 = `${a1}/${a3}`
                const label2 = `${a2}/${a3}`

                const label = statisticsHeadersTranslator(key)

                if(!label){
                    return
                }
                
                return {
                    name: label,
                    firstSide: {
                        percent: leftPercent,
                        label: label1,
                    },
                    secondSide: {
                        percent: rightPercent,
                        label: label2,
                    }
                }

            }).filter((el: any) => el !== undefined),
        }
    })

    const statDataAdapter = (fromServerStat: any) => {
        if(!fromServerStat){
            return  
        }

        const commonData = serverDataToTabs([
            commonStat(fromServerStat.setsStat)
        ])

        commonData[0].name = 'Match'
        const setData = serverDataToTabs(fromServerStat.setsStat)

        return {
            teams: [playerNamesConcatenation(fromServerStat.player1), playerNamesConcatenation(fromServerStat.player2)],
            tabs: [...commonData, ...setData]
        }
    }

    if(!matchData){
      navigate('/')
    }

    return <>
        <div className={styles.matchPage}>
            <div className={styles.contentWrapper}>
                <div className={styles.mainInfo}>
                    <ButtonMatshSelect mode={modeStat} setMode={setModeStat} clickNavigateMatch={clickNavigateMatch}/>

                    {modeStat === 0 && matchData && <ModeMatch matchData={matchData}/>}
                    {modeStat === 1 && matchData && <ModePlayer matchData={matchData} setStat={setStatistic} setClickNavigateMatch={setClickNavigateMatch}/>}
                    
                </div>
                <div className={styles.statsWrapper}>
                    {stat && <StatsWidget {...stat}/>}
                </div>
            </div>
        </div>
    </>
}
