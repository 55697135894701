import styles from "../styles.module.scss";
import {TourHeader} from "./header/TourHeader";
import {TournamentCard} from "../../../tournament-card/TournamentCard";
import React from "react";

export function Tour({ tour, tourName, tourId }: any){
    console.log('tour: ', tour, tourName, tourId)
    return (<div className={styles.tour}>
        <TourHeader name={tourName}/>
        {tour.map((match: any, mid: any) => {
            return <TournamentCard
                fullwidth
                key={mid}
                className='start fullwidth'
                topTeam={match.topTeam}
                bottomTeam={match.bottomTeam}
                topScores={match.topScores}
                bottomScores={match.bottomScores}
                winnerTeamIndex={match.winnerTeamIndex}
                inGrid={true}
                match={{...match, matchId: mid, tourId }}
                datetime={match.datetime}
                cardType={1}
                tourId={tourId}
            />;
        })}

    </div>)
}
