import {ApiProvider} from "../../app/api/api-provider";
import {useCallback, useEffect, useState} from "react";
import {pageLimit} from "./consts";

const convertTournamentsToInternalDataFormat = (tourn: any) => {
    if(!tourn){
        return [];
    }
    
    return tourn.map((t: any) => ({
        name: t.name,
        country: {
            countryName: t.countryName,
            flag: t.country,
        },
        coverage: {
            name: t.coverage?.split('|')[0],
            type: parseInt(t.coverage?.split('|')[1])
        },
        date: t.date,
        startDate: t.startDate,
        completeDate: t.completeDate,
        id: t.id || t._id,
        live: t.live === 'live',
        liveType: t.live,
        tournamentType: t.gamers,
    }))
}


const useTournamentData = (status: string | object = '') => {
    const api = ApiProvider();

    const initialTournamentState = {
        data: []
    }


    const [tournament, setTournament] = useState(initialTournamentState);
    const [error, setError] = useState();
    const [page, setPage] = useState(0);

    const [tournamentType, setTournamentType] = useState('men')
    const changePage = (live: string | object = '') => {
        const newPage = page + 1;
        setPage(newPage);
        updateTournamentsData(newPage, tournamentType, live, tournament.data);
    }




    const onTabClick = (type: string, live?: string | object) => {
        setTournament(initialTournamentState)
        setPage(0);
        setTournamentType(type);
        updateTournamentsData(0, type, live, []);
    }

    const updateTournamentsData = async (page: number = 0, type: string = 'men', live: string | object = status, tournament = []) => {
        api.getTournamentsData({
            page,
            type,
            live
        }).then((res) => {
            const newData: any = {};

            //Люто насрал с пагинацией, потом нормально сделать
            newData.data = tournament?.map((el: any) => el) || [];

            const convertedData = convertTournamentsToInternalDataFormat(res.tournaments) || [];

            const data = newData?.data.concat(convertedData) || [];

            setTournament({ data });
        })
    }


    useEffect(() => {
        updateTournamentsData();
    }, []);

    const currentTournamentsLen = tournament?.data?.length;

    const hasCurrentType = currentTournamentsLen > 0;

    const isVisibleShowMore = currentTournamentsLen >= pageLimit;

    return {
        onTabClick,
        changePage,
        tournament,
        tournamentType,
        hasCurrentType,
        isVisibleShowMore
    }
}

export default useTournamentData;
