import {Link} from "react-router-dom";
import ReactCountryFlag from "react-country-flag";
import React from "react";
import styles from './styles.module.scss'
import classNames from "classnames";
import avatar from 'images/avatar.svg'
import { TPlayer } from "app/api/types";

interface IPlayerCardProps {
    player: TPlayer,
    isPlayerPage?: boolean;
}


export const PlayerCard = ({player, isPlayerPage = false}: IPlayerCardProps) => {
    const classList = classNames(styles.playerCard, {[styles.isPlayerPage]: isPlayerPage});


    return (<Link to={`/players/${player.id}`} className={classList}>
        <img src={player.avatar ? player.avatar : avatar} alt={'error image'}/>
        <div className={styles.playerInfo}>
            <p className={styles.name}>{player.name}</p>
            <p className={styles.country}>
                <ReactCountryFlag
                    svg
                    countryCode={player.country.toLowerCase()}
                    style={{
                        width: '16px',
                        height: '12px',
                    }}
                />
            </p>
        </div>
    </Link>);
}