import styles from './styles.module.scss';
import React, {useEffect, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import {ReactComponent as Arrow} from './images/arrow.svg';
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import { FormattedMessage } from 'react-intl';

export function BreadCrumbs({menu}: any){
    const location = useLocation();

    const [isMainPage, setIsMainPage ] = useState(false);
    const [currentPath, setCurrentPath] = useState({
        path: '',
        key: '',
        type: ''
    });

    const [hasThirdLvl, setHasThirdLvl] = useState(false);

    const { pageInfo: {
        title
    } } = useSelector((state: RootState) => {
        return state;
    });

    const params = useParams();

    const { id } = params;

    const checkIsMainPage = ({ path }: any) => {
        return path === '/'
    }

    useEffect(() => {
        const { pathname, search } = location;


        setIsMainPage(checkIsMainPage({ path: pathname }));

        const currentMenuItem = menu?.find((item: any) => {
            if(item.type === 'tournaments') {
                setCurrentPath(item);
            }
            return (pathname.startsWith(item.path) && item.path !== '/' && item.type !== 'tournaments')
        });

        if(currentMenuItem) {
            setCurrentPath(currentMenuItem);
        }
        


        const checkIsThirdLvl = id && (currentPath?.path === '/players'
            || currentPath?.path === '/news' || currentPath?.type === 'tournaments') || false;

        setHasThirdLvl(checkIsThirdLvl);


    }, [location, title])

    return (<div className={styles.BreadCrumbs}>
            <Link to={'/'}><FormattedMessage id={'home'}/></Link>
            {!isMainPage && <>
                <Arrow/>
                {currentPath &&<Link to={currentPath.path}>
                    {currentPath.key && <FormattedMessage id={currentPath.key}/>}
                </Link>}
                {hasThirdLvl && <> <Arrow/> <a className={styles.thirdLvl} >{title} </a></> }
            </>}
        </div>)
}