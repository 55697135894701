// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_statisticsUnitWrapper__ftqbo {
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding-top: 10px;
  padding-right: 16px;
  padding-left: 16px;
}
.styles_statisticsUnitWrapper__labels__wOAnm {
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/features/statisticsUnit/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;AACJ","sourcesContent":[".statisticsUnitWrapper {\n  display: flex;\n  flex-direction: column;\n  gap: 3px;\n  padding-top: 10px;\n  padding-right: 16px;\n  padding-left: 16px;\n\n  &__labels {\n    display: flex;\n    justify-content: space-between;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statisticsUnitWrapper": `styles_statisticsUnitWrapper__ftqbo`,
	"statisticsUnitWrapper__labels": `styles_statisticsUnitWrapper__labels__wOAnm`
};
export default ___CSS_LOADER_EXPORT___;
