import styles from './styles.module.scss'
import {AllNews} from "./request";
import {useNavigate} from "react-router-dom";
import { FormattedMessage } from 'react-intl';


export function NewsPage () {
    const navigate = useNavigate()

    return (<>
            <div className={styles.news}>
                <div className={styles.newsHeader}>
                    <FormattedMessage id={'news'}/>
                </div>
                <AllNews navi={navigate}/>
            </div>
        </>
    )
}

export default NewsPage;