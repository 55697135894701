import {TTeam} from "../../entities/ui/TeamList/types";

export type TScore = {
    score: number;
    tie?: number;
}

export enum EWinnerTeamIndex {
    topTeamIsWin,
    bottomTeamIsWin,
    noOneTeamIsWin,
}

export interface ITournamentCardArgs {
    topTeam: TTeam;
    winnerTeamIndex: EWinnerTeamIndex;
    bottomTeam: TTeam;
    topScores: TScore[];
    bottomScores: TScore[];
    inGrid: boolean;
    match: any;
    matchId?: number,
    tourId?: number,
    className?: string;
    fullwidth?: boolean
    datetime?: string;
    cardType?: number;
}