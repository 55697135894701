import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import './index.css';
import MainPage from 'pages/main-page/MainPage';
import reportWebVitals from './reportWebVitals';
import { store } from "app/store";
import { Provider } from "react-redux";
import {TournamentPage} from "pages/tournament-page/TournamentPage";
import NewsPage from "pages/news-page/NewsPage";
import OneNews from "pages/OneNews-page/OneNews";
import PlayersPage from "pages/players-page/PlayersPage";
import PlayerPage from "pages/Player-page/PlayerPage";
import {MatchPage} from "pages/match-page/MatchPage";
import {PlayersLayout} from "app/layouts/players-layout/players-layout";
import {MainLayout} from "./app/layouts/main-layout/main-layout";
import { InternationalizationProvider } from 'InternationalizationProvider';
import TournamentCompletePage from 'pages/tournament-complete-page/TournamentCompletePage';

const router = createBrowserRouter([
    {
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <MainPage />
            },
            {
                path: '/tournaments/complete',
                element: <TournamentCompletePage />
            },
            {
                path: '/tournaments/:id',
                element: <TournamentPage />
            },
            {
                path: '/match/:systemMatchId',
                element: <MatchPage />
            },
            {
                path: '/news',
                element: <NewsPage />
            },
            {
                path: `/news/:id`,
                element: <OneNews />
            },
        ]
    },

    {
        element: <PlayersLayout />,
        children: [
            {
                path: '/players',
                element: <PlayersPage />
            },
            {
                path: '/players/:id',
                element: <PlayerPage />
            },
        ]
    },
])


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <InternationalizationProvider>
            <RouterProvider router={router} />
        </InternationalizationProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
