import {IMobileNewsItemProps} from "./types";
import styles from './styles.module.scss';
import { FormattedMessage } from "react-intl";


export const MobileNewsItem = ({onClickHandler, children}: IMobileNewsItemProps) => {
    return (<div onClick={onClickHandler} className={styles.newsItem}>
        <p>
            {children}
            <span>
                <FormattedMessage id={"readMore"}/>
            </span>
        </p>
    </div>)
}