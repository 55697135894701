// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_ScoreList__\\+dJHT{
    display: flex;
    flex-direction: row;
    min-width: 50px;

}

.styles_ScoreList__\\+dJHT > div:nth-child(2n + 1){
    background: #dedede;
}

.styles_ScorePart__aSq-5{
    width: 27px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.styles_BaseScore__08hp9{
    position: relative;
}
.styles_TieBreak__z82f5{
    position: absolute;
    font-size: 12px;
    top: -7px;
    right: -5px;
}`, "",{"version":3,"sources":["webpack://./src/entities/ui/scores-list/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;;AAEnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,SAAS;IACT,WAAW;AACf","sourcesContent":[".ScoreList{\n    display: flex;\n    flex-direction: row;\n    min-width: 50px;\n\n}\n\n.ScoreList > div:nth-child(2n + 1){\n    background: #dedede;\n}\n\n.ScorePart{\n    width: 27px;\n    height: 100%;\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.BaseScore{\n    position: relative;\n}\n.TieBreak{\n    position: absolute;\n    font-size: 12px;\n    top: -7px;\n    right: -5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScoreList": `styles_ScoreList__+dJHT`,
	"ScorePart": `styles_ScorePart__aSq-5`,
	"BaseScore": `styles_BaseScore__08hp9`,
	"TieBreak": `styles_TieBreak__z82f5`
};
export default ___CSS_LOADER_EXPORT___;
