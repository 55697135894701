import {TCountry} from "../../types";
import Flag from "react-world-flags";
import {inspect} from "util";
import styles from '../../styles.module.scss';

export function CountryBar({countryName, flag}: TCountry){
    return <div className={styles.CountryBar}>
        <Flag code={flag} className={styles.CountryFlag} />
        <div className={styles.CountryName}>{countryName}</div>
    </div>
}