import {Header} from "shared/header";
import Navigation from "features/navigation/navigation";
import {Footer} from "widgets/footer/footer";

import {
    Outlet
} from 'react-router-dom';


export const MainLayout = ({children}: any) => {
    return (<div className="App">
        <Header/>
        <Navigation/>
        <main>
            <Outlet/>
        </main>
        <Footer/>
    </div>
)
}