import styles from "../../../main-page/styles.module.scss";
import {TMatch} from "../../../../app/store/slices/match-beats-slice";
import {Game} from "../Game/Game";
import {Aces, BreakPoint, DoubleFaults} from "../../svg";
import React, {createRef, useCallback, useEffect, useState} from "react";
import {HoldSign} from "../../GridPage";
import {IMatchBeatsWrapperArgs} from "./types";
import { isCompositeComponent } from "react-dom/test-utils";

const WIDGET_SCROLL_UPDATE_INTERVAL = 100000
let intervalIsStarted = false
let autoScrollHybernationTimeout: any;

export function MatchBeatsWidget({beats, autoScroll = false}: IMatchBeatsWrapperArgs){

    const [thisMobile, setThisMobile] = useState(false);
    const [isActiveGrub, setIsActiveGrub] = useState(false);
    const [offset, setOffset] = useState(0);
    const [clickPosition, setClickPosition] = useState(-1);
    const [movingPosition, setMovingPosition] = useState(-1);
    const [autoScrollOn, setAutoScrollOn] = useState(autoScroll)

    const widthWindow = createRef<HTMLDivElement>();
    const widthBlock = createRef<HTMLDivElement>();

    const activateGrab = useCallback((event: any) => {
        if(autoScrollHybernationTimeout){
            clearTimeout(autoScrollHybernationTimeout)
        }
        setAutoScrollOn(false)
        setIsActiveGrub(true)
        setClickPosition(event.clientX)
    }, []);

    const activateGrabMobile = useCallback((event: any) => {
        setAutoScrollOn(false)
        setThisMobile(true)
        setIsActiveGrub(true)
        setClickPosition(event.changedTouches[0].clientX)
    }, []);

    const deactivateGrab = useCallback(() => {
        setThisMobile(false)
        setIsActiveGrub(false)
        setClickPosition(-1);
        autoScrollHybernationTimeout = setTimeout(() => setAutoScrollOn(autoScroll), WIDGET_SCROLL_UPDATE_INTERVAL)
        
    }, []);

    useEffect(() => {
        if(!autoScroll){
            return
        }
        const scrollToTheRight = () => {
            if(!widthWindow.current || !widthBlock.current || !autoScrollOn){
                return;
            }
            setOffset(widthWindow.current.offsetWidth - (widthBlock.current.offsetWidth + 30))
        }
        intervalIsStarted = true
        const scrollInterval = setInterval(scrollToTheRight, WIDGET_SCROLL_UPDATE_INTERVAL)
        scrollToTheRight()
        return () => {
            clearInterval(scrollInterval)
        }
    }, [widthWindow, widthBlock])

    const makeShortantName = (name: string) => {
        const sp = name.split(' ')
        
        return `${sp[0][0]} ${sp[1]}`
    }

    const doGrab = useCallback((event: any) => {
        if(!isActiveGrub || !widthWindow.current || !widthBlock.current){
            return;
        }
        let newOffset = offset - (clickPosition - (thisMobile?event.changedTouches[0].clientX:event.clientX));
        if(newOffset > 10){
            newOffset = 10
        }

        if(newOffset < widthWindow.current.offsetWidth - (widthBlock.current.offsetWidth + 30)){
            newOffset = widthWindow.current.offsetWidth - (widthBlock.current.offsetWidth + 30)
        }
        setOffset(newOffset)
        setClickPosition(thisMobile?event.changedTouches[0].clientX:event.clientX)
    }, [clickPosition]);

    const makeTieResults = (tie: any, id: number) => {

        const [tie1, tie2] = tie.at(-1)
        if(id === 0){
            return tie1
        }

        return tie2
    }

    return <div
        ref={widthWindow}
        className={styles.MatchBeatsWrapper}>
        <div
            onMouseDown={activateGrab}
            onMouseUp={deactivateGrab}
            onMouseMove={doGrab}
            onMouseLeave={deactivateGrab}

            onTouchStart={activateGrabMobile}
            onTouchEnd ={deactivateGrab}
            onTouchMove ={doGrab}
            onTouchCancel ={deactivateGrab}

            className={styles.MatchBeats}
            style={{transform: `translate(${offset}px, 0)`}} ref={widthBlock}>
            <div className={styles.PlayersNamesBlock}>
                {
                    beats[0].opponents.map((opponent: any /*todo*/, k: number) => {
                        return <div key={opponent.name + k} className={styles.OpponentName}>
                            <div className={styles.OpponentNameLeftDivider}/>
                            <div className={styles.OpponentNameText}>{opponent.name}</div>
                        </div>
                    })
                }
            </div>

            {beats.map((beat: any, bid: number) => <><div className={styles.SetName}>{beat.setName}</div>{beat.data.map((game: any, gameId: number) => {
                const wholeGameSum = game.results.setScore[0] + game.results.setScore[1]
                return <>
                    <Game key={bid} game={game}/>
                    {gameId < wholeGameSum && wholeGameSum !== 0 && game.results && (game?.scores?.at(-1)?.[0] === "Game" || game?.scores?.at(-1)?.[1] === "Game") && <div className={styles.GameResults}>
                        {game.holds === 1 && <div className={styles.Holds}>{makeShortantName(beat.opponents[0].name)} HOLDS</div> }
                        {game.holds !== 1 && <div className={styles.GameTime}>
                            <img className={styles.TimeImage} src={'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA4IDgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMzNjM2MzY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNNCw4QTQsNCwwLDEsMSw4LDQsNCw0LDAsMCwxLDQsOFpNNCwuNUEzLjUsMy41LDAsMSwwLDcuNSw0LDMuNSwzLjUsMCwwLDAsNCwuNVptMCwwIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0yNDIsMTA1LjkxNWEuMjQ4LjI0OCwwLDAsMS0uMTc3LS4wNzNsLTEuNzUtMS43NWEuMjUuMjUsMCwwLDEtLjA3My0uMTc3di0yLjMzM2EuMjUuMjUsMCwwLDEsLjUsMHYyLjIzbDEuNjc3LDEuNjc3YS4yNS4yNSwwLDAsMS0uMTc3LjQyN1ptMCwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjM2LjI1IC05OS43NDkpIi8+PC9zdmc+'} />
                            {
                                game.results.time}</div> }
                        <div className={styles.GameScores}>
                            <div className={styles.GameScoreBar}>

                                {game.holds === 1 ? <HoldSign /> : <div className={styles.HoldsScore}></div>}
                                <div className={styles.ResultScore}>{game.results.setScore[0]}</div>
                                {game.isTieBreak && <div className={styles.TieTop}>
                                    {(makeTieResults(game.scores, 0) === "Game" && game.isTieBreak === true && Number(game.scores[game.scores.length - 2][0]) + 1) || makeTieResults(game.scores, 0)}
                                </div>}


                                {game.holds === 2 ? <HoldSign /> : <div className={styles.HoldsScore}></div>}
                                <div className={styles.ResultScore}>{game.results.setScore[1]}</div>
                                {game.isTieBreak && <div className={styles.TieBottom}>
                                    {(makeTieResults(game.scores, 1) === "Game" && game.isTieBreak === true && Number(game.scores[game.scores.length - 2][1]) + 1) || makeTieResults(game.scores, 1)}
                                </div>}

                            </div>
                        </div>
                        {game.holds === 2 && <div className={styles.Holds}>{makeShortantName(beat.opponents[1].name)} HOLDS</div> }
                        {game.holds !== 2 && <div className={styles.GameTime}>
                            <img className={styles.TimeImage} src={'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCA4IDgiPjxkZWZzPjxzdHlsZT4uYXtmaWxsOiMzNjM2MzY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNNCw4QTQsNCwwLDEsMSw4LDQsNCw0LDAsMCwxLDQsOFpNNCwuNUEzLjUsMy41LDAsMSwwLDcuNSw0LDMuNSwzLjUsMCwwLDAsNCwuNVptMCwwIi8+PHBhdGggY2xhc3M9ImEiIGQ9Ik0yNDIsMTA1LjkxNWEuMjQ4LjI0OCwwLDAsMS0uMTc3LS4wNzNsLTEuNzUtMS43NWEuMjUuMjUsMCwwLDEtLjA3My0uMTc3di0yLjMzM2EuMjUuMjUsMCwwLDEsLjUsMHYyLjIzbDEuNjc3LDEuNjc3YS4yNS4yNSwwLDAsMS0uMTc3LjQyN1ptMCwwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjM2LjI1IC05OS43NDkpIi8+PC9zdmc+'} />
                            {game.results.time}</div> }
                    </div>}
                </>
            })}</>)}
        </div>
        <div className={styles.UserSchema}>
            <BreakPoint/>
            <DoubleFaults/>
            <Aces/>
        </div>
    </div>
}
