// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_statisticsLabel__iAngx {
  color: #195679;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}`, "",{"version":3,"sources":["webpack://./src/features/statisticsUnit/statisticsLabel/styles.module.scss"],"names":[],"mappings":"AAAC;EACK,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;AACN","sourcesContent":[" .statisticsLabel {\n      color: #195679;\n      font-size: 10px;\n      font-style: normal;\n      font-weight: 500;\n      line-height: normal;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statisticsLabel": `styles_statisticsLabel__iAngx`
};
export default ___CSS_LOADER_EXPORT___;
