import styles from "./styles.module.scss";
import { TournamentCard } from "../../tournament-card/TournamentCard";
import { createRef, useCallback, useEffect, useState } from "react";
import { TMatchGridPoints, TSvgParams, TToDrawElement } from "./types";
import { generateWholeGridPoints } from "./procedures";

import {
    ELLIMINATION_TOURNAMENT_CARD,
    TOP_GRID_CARD,
    BOTTOM_GRID_CARD,
    FOR_SEVENTH_PLACE_CARD,
    FOR_FIFTH_PACE_CARD,
    HEMIFINAL,
    FINAL,
} from 'shared/constants'

import {
    GRID_MATCH_BLOCK_SIZE,
    GRID_FIRST_BLOCK_HEIGHT,
    GRID_COLS_OFFSET,
    GRID_FIRST_BLOCK_LEFT_OFFSET,
    GRID_FIRST_BLOCK_TOP_OFFSET,
} from './constants'
import { MatchCard } from "./MatchCard";
import { FormattedMessage } from "react-intl";

export const MainTournamentGridContainer = (
    {
        tournament,
        activateGrabMobile,
        tournamentGridHeight,
    }: any) => {

    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);

    const [isActiveGrub, setIsActiveGrub] = useState(false);
    // const [clickPositionX, setClickPositionX] = useState(-1);
    // const [clickPositionY, setClickPositionY] = useState(-1);

    const [prevX, setPrevX] = useState(0)
    const [prevY, setPrevY] = useState(0)


    const [svgParams, setSvgParams] = useState<TSvgParams | undefined>()
    const [tournamentGridPoints, setTournamentGridPoints] = useState<TToDrawElement[] | null>(null)

    useEffect(() => {

        console.log('tournament was updated 2')

        setSvgParams({
            width: 2000,
            height: 1200,
        })

        setTournamentGridPoints(generateWholeGridPoints(tournament.upperGrid))
    }, [tournament])


    const activateGrab = useCallback((event: any) => {
        setIsActiveGrub(true)
        setPrevX(event.nativeEvent.pageX)
        setPrevY(event.nativeEvent.pageY)
    }, []);

    const deactivateGrab = (event: any) => {
        setIsActiveGrub(false)
        // setClickPositionX(0);
        // setClickPositionY(0);
    }

    const doGrab = (event: any) => {
        if (!widthWindow.current || !widthBlock.current) {
            return;
        }

        if (!isActiveGrub) {
            return;
        }

        const deltaX = prevX - event.nativeEvent.pageX
        const deltaY = prevY - event.nativeEvent.pageY

        setPrevX(event.nativeEvent.pageX)
        setPrevY(event.nativeEvent.pageY)

        setOffsetY(offsetY - deltaY)
        setOffsetX(offsetX - deltaX)
    }


    const [timeTrans, setTimeTrans] = useState('')



    const widthWindow = createRef<HTMLDivElement>();
    const widthBlock = createRef<HTMLDivElement>();

    const gridRef = createRef<HTMLDivElement>();

    const calcylateMatchXPosition = (tgp: any, tid: number, mid: number) => {

        if(tid === 0){
            return tournamentGridPoints && tournamentGridPoints[tid].lines[mid].x.start || 0
        }

        return (tournamentGridPoints && tournamentGridPoints[tid].lines[mid].x.start || 0) + GRID_COLS_OFFSET
    }

    return <div className={styles.TournamentGridContainer} ref={gridRef}

        // onMouseDown={activateGrab}
        // onMouseUp={deactivateGrab}
        // onMouseMove={doGrab}
        // onMouseLeave={deactivateGrab}

    >

        <div className={styles.containerMeshHeader}>
            <div className={styles.containerHeader}>
                {/* <div
                    className={styles.TournamentGridHeader}
                    style={{ transform: `translate(${offsetX}px, 0)`, transition: timeTrans }}
                >
                    {tournament.map((tour: any, tid: number) => {
                        return <div key={tid}>{tour.name}</div>
                    })}
                </div> */}
            </div>

            <div className={styles.containerMesh} ref={widthWindow}>
                <div
                    onMouseDown={activateGrab}
                    onDoubleClick={activateGrab}
                    onMouseUp={deactivateGrab}
                    onMouseMove={doGrab}
                    onMouseLeave={deactivateGrab}

                    onTouchStart={activateGrabMobile}
                    onTouchEnd={deactivateGrab}
                    onTouchMove={doGrab}
                    onTouchCancel={deactivateGrab}

                    // style={{transform: `translate(${offsetX}px, ${offsetY}px)`, transition: timeTrans}}
                    ref={widthBlock}

                    className={styles.TournamentGrid}
                >

                    <div className={styles.GridCanvas}>
                        <svg width={svgParams?.width} height={svgParams?.height} xmlns="http://www.w3.org/2000/svg" transform={`translate(${offsetX}, ${offsetY})`}>

                            {tournamentGridPoints?.map((toDraw: TToDrawElement) => {
                                return <>
                                    {toDraw.connections.map((coords: TMatchGridPoints) => {
                                        const key = `${coords.x.start}${coords.x.stop}${coords.y.start}${coords.y.stop}`
                                        return <line key={key} x1={coords.x.start} x2={coords.x.stop} y1={coords.y.start} y2={coords.y.stop} stroke="var(--color-blue)" stroke-dasharray="2,1" />
                                    })}
                                    {toDraw.lines.map((coords: TMatchGridPoints) => {
                                        const key = `${coords.x.start}${coords.x.stop}${coords.y.start}${coords.y.stop}`
                                        return <line key={key} x1={coords.x.start} x2={coords.x.stop} y1={coords.y.start} y2={coords.y.stop} stroke="var(--color-blue)" stroke-dasharray="2,1" />
                                    })}
                                </>
                            })}

                            {tournament.upperGrid.map((tour: any, tid: number) => {
                                return <>
                                    {tour.matches.map((match: any, mid: number) =>
                                        <foreignObject
                                            key={match._id + match.team1Names?.at(-1) + match.team2Names?.at(-1)}
                                            x={
                                                calcylateMatchXPosition(tournamentGridPoints, tid, mid)
                                            }
                                            y={
                                                (tournamentGridPoints && tournamentGridPoints[tid].lines[mid].y.start || 0) - (GRID_MATCH_BLOCK_SIZE / 4) + 6
                                            }

                                            width={GRID_MATCH_BLOCK_SIZE} height={GRID_FIRST_BLOCK_HEIGHT}
                                        >
                                            <TournamentCard
                                                key={mid + tid}
                                                className={tid === 0 ? 'start' : undefined}
                                                topTeam={match.topTeam}
                                                bottomTeam={match.bottomTeam}
                                                topScores={match.topScores}
                                                bottomScores={match.bottomScores}
                                                winnerTeamIndex={match.winnerTeamIndex}
                                                inGrid={true}
                                                match={match}
                                                tourId={tid}
                                                matchId={mid}
                                                cardType={TOP_GRID_CARD}
                                            /></foreignObject>)}
                                </>
                            })}

                            {tournament.bottomGrid.map((bottomTour: any[], tid: number) => {
                                return bottomTour.map((match: any, mid: number) => {

                                    if(tid === 2 && mid === 0){
                                        return <MatchCard
                                            key={match?.team1Names?.length > 0 ? match.team1Names[0]._id + match.team2Names[0]._id : tid * 100 + mid}
                                            x={GRID_FIRST_BLOCK_LEFT_OFFSET + (( GRID_COLS_OFFSET + GRID_MATCH_BLOCK_SIZE ) * tid)}
                                            y={530 + (120 * mid) + 55}
                                            match={match}
                                            tourId={tid}
                                            matchId={mid}
                                            cardType={BOTTOM_GRID_CARD}
                                            />    
                                    }

                                    return <MatchCard
                                            key={match?.team1Names?.length > 0 ? match.team1Names[0]._id + match.team2Names[0]._id : tid * 100 + mid}
                                            x={GRID_FIRST_BLOCK_LEFT_OFFSET + (( GRID_COLS_OFFSET + GRID_MATCH_BLOCK_SIZE ) * tid)}
                                            y={530 + (120 * mid)}
                                            match={match}
                                            tourId={tid}
                                            matchId={mid}
                                            cardType={BOTTOM_GRID_CARD}
                                            />
                                })
                            })}

                            <line 
                                x1={GRID_FIRST_BLOCK_LEFT_OFFSET} 
                                x2={GRID_FIRST_BLOCK_LEFT_OFFSET + 710} 
                                y1={535} 
                                y2={535} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />
                            
                            <line 
                                x1={GRID_FIRST_BLOCK_LEFT_OFFSET} 
                                x2={GRID_FIRST_BLOCK_LEFT_OFFSET + 710} 
                                y1={30} 
                                y2={30} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />

                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 2} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 2} 
                                y1={530 + 83} 
                                y2={530 + 100 * 2 + 3} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />


                            {/* Линия соединяющая нижнюю сетку и матч за 7е место */}

                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE)} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE)} 
                                y1={530 + 83} 
                                y2={885} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />
                            
                            {/* Линия соединяющая нижнюю сетку и матч за 5е место */}

                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 2} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 2} 
                                y1={530 + 83} 
                                y2={885} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />

                            {/* Линия соединяющая нижнюю сетку и нижний матч 1\2 финала */}

                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 2} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 2 + 10} 
                                y1={530 + 83 + 55} 
                                y2={530 + 83 + 55} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />
                            
                            {/* Линия соединяющая матчи полуфинала */}
                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 3} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 3} 
                                y1={290} 
                                y2={700} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />
                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 3} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 3 + 10} 
                                y1={483} 
                                y2={483} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />

                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 2} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 2 + 10} 
                                y1={530 + 83 + 55} 
                                y2={530 + 83 + 55} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />
                            
                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 4 - 10} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 4 + 10} 
                                y1={483} 
                                y2={483} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />
                            
                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 3} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 4 - 5} 
                                y1={290} 
                                y2={290} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />
                            
                            <line 
                                x1={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 4 - 5} 
                                x2={(GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE) * 4 - 5} 
                                y1={290} 
                                y2={483} 
                                stroke="var(--color-blue)" 
                                strokeDasharray="2,1" 
                                />


                                <foreignObject x={30} y={20} width={110} height={80}>
                                    <p style={{
                                        backgroundColor: '#E6ECF0',
                                        fontSize: '12px',
                                    }}>
                                        <FormattedMessage id={'upperMesh'}/>
                                    </p>
                                </foreignObject>

                                <foreignObject x={30} y={525} width={110} height={80}>
                                    <p style={{
                                        backgroundColor: '#E6ECF0',
                                        fontSize: '12px',
                                    }}>
                                        <FormattedMessage id={'bottomMesh'}/>
                                    </p>
                                </foreignObject>

                                <foreignObject x={750} y={210} width={110} height={80}>
                                    <p style={{
                                        backgroundColor: '#E6ECF0',
                                        fontSize: '12px',
                                    }}>1/2 
                                        <FormattedMessage id={'finals'}/>
                                    </p>
                                </foreignObject>
                                <foreignObject x={750} y={590} width={110} height={80}>
                                    <p style={{
                                        backgroundColor: '#E6ECF0',
                                        fontSize: '12px',
                                    }}>1/4 
                                        <FormattedMessage id={'finals'}/>
                                    </p>
                                </foreignObject>
                                <foreignObject x={1100} y={400} width={110} height={80}>
                                    <p style={{
                                        backgroundColor: '#E6ECF0',
                                        fontSize: '12px',
                                    }}>1/2
                                    <FormattedMessage id={'finals'} />
                                    </p>
                                </foreignObject>
                                <MatchCard 
                                    x={1085}
                                    y={400}
                                    match={tournament.hemifinal}
                                    tourId={-1}
                                    matchId={-1}
                                    cardType={HEMIFINAL}
                                />


                                <foreignObject x={1450} y={400} width={110} height={80}>
                                    <p style={{
                                        backgroundColor: '#E6ECF0',
                                        fontSize: '12px',
                                    }}>
                                        <FormattedMessage id={'theFinal'}/>
                                    </p>
                                </foreignObject>
                                <MatchCard 
                                    x={1085 + GRID_MATCH_BLOCK_SIZE + GRID_FIRST_BLOCK_LEFT_OFFSET}
                                    y={400}
                                    match={tournament.final}
                                    tourId={-1}
                                    matchId={-1}
                                    cardType={FINAL}
                                />

                                <foreignObject x={30} y={525 + 275} width={110} height={80}>
                                    <p style={{
                                        backgroundColor: '#E6ECF0',
                                        fontSize: '12px',
                                    }}>
                                        <FormattedMessage id={'for7thPlace'}/>
                                    </p>
                                </foreignObject>
                                <MatchCard 
                                    x={GRID_FIRST_BLOCK_LEFT_OFFSET}
                                    y={800}
                                    match={tournament.forSeventPlace}
                                    tourId={-1}
                                    matchId={-1}
                                    cardType={FOR_SEVENTH_PLACE_CARD}
                                />

                                <foreignObject x={30 + GRID_MATCH_BLOCK_SIZE + GRID_FIRST_BLOCK_LEFT_OFFSET} y={525 + 275} width={110} height={80}>
                                    <p style={{
                                        backgroundColor: '#E6ECF0',
                                        fontSize: '12px',
                                    }}>
                                        <FormattedMessage id={'for5thPlace'}/>
                                    </p>
                                </foreignObject>
                                <MatchCard 
                                    x={GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE + GRID_FIRST_BLOCK_LEFT_OFFSET}
                                    y={800}
                                    match={tournament.forFifthPlace}
                                    tourId={-1}
                                    matchId={-1}
                                    cardType={FOR_FIFTH_PACE_CARD}
                                />

                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
