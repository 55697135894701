import styles from './styles.module.css';
import {ITeamListArgs} from "./types";
import '../../../../src/shared/flags.css'
import ReactCountryFlag from "react-country-flag"

const playerNameToShortName =  (pname: string) => {
    const sp = pname.split(' ');
    return `${sp[0][0]} ${sp[1]}`
}

export function TeamList({team}: ITeamListArgs){
    return <div className={styles.TeamList}>
        {team.players.map(player => <div key={player.fullName} className={styles.PlayerLine}>
            <div className={styles.FlagBlock}>
                <ReactCountryFlag 
                    svg
                    countryCode={player.country.toLowerCase()}
                    style={{
                        width: '26px',
                        height: '20px',
                    }}
                />
                {/* <div className={styles.FlagBlock}><div className={`flag ${player.country.toLowerCase()} ${styles.Flag}`}></div></div> */}
                <div className={styles.ISO}>{player.country}</div>
            </div>
            <div className={styles.PlayerName}>{playerNameToShortName(player.fullName)}</div>
        </div>)}
    </div>
}