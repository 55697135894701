import styles from "./styles.module.scss";
import {Link, useNavigate} from "react-router-dom";
import {BreadCrumbs} from "../../entities/ui/breadcrumbs/BreadCrumbs";
import React from "react";
import { FormattedMessage } from "react-intl";

export function Navigation() {
    const menu = [
        {
            key: 'home',
            path: '/',
            type: 'main'
        },
        {
            key: 'news',
            path: '/news',
            type: 'news'
        },
        {
            key: 'tournaments',
            path: '/',
            type: 'tournaments',
            action: () => 
                window.scrollTo({
                    top: 595,
                    left: 0,
                    behavior: "smooth"
                })
        },
        {
            key: 'players',
            path: '/players',
            type: 'players'
            
        },
        {
            key: 'rating',
            path: '/rating',
            type: 'rating'
            
        },
        {
            key: 'results',
            path: '/tournaments/complete',
            type: 'results'
        },
        {
            key: 'documents',
            path: '/documents',
            type: 'documents'
        }
    ];

    const renderMenu = menu.map((item, index) => {
        return(<Link onClick={item.action} to={item.path} key={index}><FormattedMessage id={item.key}/></Link>)
    })

    return(
        <div className={styles.backgroundNavigation}>
            <div>
                <BreadCrumbs menu={menu}/>
                <div className={styles.containerNavigation}>
                    {renderMenu}
                </div>
                <div/>
            </div>
        </div>
    );
}
export default Navigation;
