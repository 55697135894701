import styles from "./styles.module.scss";
import { TournamentCard } from "../../tournament-card/TournamentCard";
import { createRef, useCallback, useEffect, useState } from "react";
import { TMatchGridPoints, TSvgParams, TToDrawElement } from "./types";
import { generateWholeGridPoints } from "./procedures";

import {
    GRID_MATCH_BLOCK_SIZE,
    GRID_FIRST_BLOCK_HEIGHT,
    GRID_COLS_OFFSET,
} from './constants'
import { QualificationCardWinner } from "widgets/qualification-card-winner/QualificationCardWinner";

export const TournamentGridContainer = (
    {
        tournament,
        activateGrabMobile,
        tournamentGridHeight,
    }: any) => {

    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [isActiveGrub, setIsActiveGrub] = useState(false);
    // const [clickPositionX, setClickPositionX] = useState(-1);
    // const [clickPositionY, setClickPositionY] = useState(-1);

    const [prevX, setPrevX] = useState(0)
    const [prevY, setPrevY] = useState(0)


    const [svgParams, setSvgParams] = useState<TSvgParams | undefined>()
    const [tournamentGridPoints, setTournamentGridPoints] = useState<TToDrawElement[] | null>(null)

    useEffect(() => {
        setSvgParams({
            width: (gridRef.current?.clientWidth || 512) * 10,
            height: (gridRef.current?.clientHeight || 512) * 10,
        })

        setTournamentGridPoints(generateWholeGridPoints(tournament))
    }, [tournament])





    const activateGrab = useCallback((event: any) => {
        setIsActiveGrub(true)
        setPrevX(event.nativeEvent.pageX)
        setPrevY(event.nativeEvent.pageY)
    }, []);

    const deactivateGrab = (event: any) => {
        setIsActiveGrub(false)
        // setClickPositionX(0);
        // setClickPositionY(0);
    }


    const doGrab = (event: any) => {
        if (!widthWindow.current || !widthBlock.current) {
            return;
        }

        if (!isActiveGrub) {
            return;
        }

        const deltaX = prevX - event.nativeEvent.pageX
        const deltaY = prevY - event.nativeEvent.pageY

        setPrevX(event.nativeEvent.pageX)
        setPrevY(event.nativeEvent.pageY)

        setOffsetY(offsetY - deltaY)
        setOffsetX(offsetX - deltaX)
    }

    const [timeTrans, setTimeTrans] = useState('')



    const widthWindow = createRef<HTMLDivElement>();
    const widthBlock = createRef<HTMLDivElement>();

    const gridRef = createRef<HTMLDivElement>();

    const calcylateMatchXPosition = (tgp: any, tid: number, mid: number) => {

        if(tid === 0){
            return tournamentGridPoints && tournamentGridPoints[tid].lines[mid].x.start || 0
        }

        return (tournamentGridPoints && tournamentGridPoints[tid].lines[mid].x.start || 0) + GRID_COLS_OFFSET
    }

    console.log('ellimination', tournament)

    return <div className={styles.TournamentGridContainer} ref={gridRef}

        // onMouseDown={activateGrab}
        // onMouseUp={deactivateGrab}
        // onMouseMove={doGrab}
        // onMouseLeave={deactivateGrab}

    >

        <div className={styles.containerMeshHeader}>
            <div className={styles.containerHeader}>
                <div
                    className={styles.TournamentGridHeader}
                    style={{ transform: `translate(${offsetX}px, 0)`, transition: timeTrans }}
                >
                    {tournament.map((tour: any, tid: number) => {
                        return <div key={tid}>{tour.name}</div>
                    })}
                </div>
            </div>

            <div className={styles.containerMesh} ref={widthWindow}>
                <div
                    onMouseDown={activateGrab}
                    onDoubleClick={activateGrab}
                    onMouseUp={deactivateGrab}
                    onMouseMove={doGrab}
                    onMouseLeave={deactivateGrab}

                    // onTouchStart={activateGrabMobile}
                    // onTouchEnd={deactivateGrab}
                    // onTouchMove={doGrab}
                    // onTouchCancel={deactivateGrab}

                    // style={{transform: `translate(${offsetX}px, ${offsetY}px)`, transition: timeTrans}}
                    ref={widthBlock}

                    className={styles.TournamentGrid}
                >

                    <div className={styles.GridCanvas}>
                        <svg width={svgParams?.width} height={svgParams?.height} xmlns="http://www.w3.org/2000/svg" transform={`translate(${offsetX}, ${offsetY})`}>

                            {tournamentGridPoints?.map((toDraw: TToDrawElement, id: number) => {
                                if (id + 1 === tournament.length) {
                                    return <>
                                        {toDraw.lines.map((coords: TMatchGridPoints) => {
                                            const key = `${coords.x.start}${coords.x.stop}${coords.y.start}${coords.y.stop}`
                                            return <line key={key} x1={coords.x.start} x2={coords.x.stop + 10} y1={coords.y.start} y2={coords.y.stop} stroke="var(--color-blue)" stroke-dasharray="2,1" />
                                        })}
                                    </>
                                }
                                if(id + 2 > tournament.length) {
                                    return
                                }
                                return <>
                                    {toDraw.connections.map((coords: TMatchGridPoints) => {
                                        const key = `${coords.x.start}${coords.x.stop}${coords.y.start}${coords.y.stop}`
                                        return <line key={key} x1={coords.x.start} x2={coords.x.stop} y1={coords.y.start} y2={coords.y.stop} stroke="var(--color-blue)" stroke-dasharray="2,1" />
                                    })}
                                    {toDraw.lines.map((coords: TMatchGridPoints) => {
                                        const key = `${coords.x.start}${coords.x.stop}${coords.y.start}${coords.y.stop}`
                                        return <line key={key} x1={coords.x.start} x2={coords.x.stop} y1={coords.y.start} y2={coords.y.stop} stroke="var(--color-blue)" stroke-dasharray="2,1" />
                                    })}
                                </>
                            })}

                            {tournament.map((tour: any, tid: number) => {
                                return <>
                                    {tour.matches.map((match: any, mid: number) =>
                                        <foreignObject
                                            x={
                                                calcylateMatchXPosition(tournamentGridPoints, tid, mid)
                                            }
                                            y={
                                                (tournamentGridPoints && tournamentGridPoints[tid].lines[mid].y.start || 0) - (GRID_MATCH_BLOCK_SIZE / 4) + 6
                                            }

                                            width={GRID_MATCH_BLOCK_SIZE} height={GRID_FIRST_BLOCK_HEIGHT}
                                        >
                                            <TournamentCard
                                                key={mid + tid}
                                                className={tid === 0 ? 'start' : undefined}
                                                topTeam={match.topTeam}
                                                bottomTeam={match.bottomTeam}
                                                topScores={match.topScores}
                                                bottomScores={match.bottomScores}
                                                winnerTeamIndex={match.winnerTeamIndex}
                                                inGrid={true}
                                                match={match}
                                                tourId={tid}
                                                matchId={mid}
                                            />
                                        </foreignObject>)}
                                </>
                            })}
                            {tournament.at(-1).matches.map((match: any, mid: number) =>
                                        <foreignObject
                                            x={
                                                calcylateMatchXPosition(tournamentGridPoints, tournament.length - 1, mid) + 360
                                            }
                                            y={
                                                (tournamentGridPoints && tournamentGridPoints[tournament.length - 1].lines[mid].y.start || 0) - (GRID_MATCH_BLOCK_SIZE / 4) + 6
                                            }

                                            width={GRID_MATCH_BLOCK_SIZE} height={GRID_FIRST_BLOCK_HEIGHT}
                                        >
                                            <QualificationCardWinner
                                                key={mid + tournament.length - 1}
                                                className={tournament.length - 1 === 0 ? 'start' : undefined}
                                                topTeam={match.topTeam}
                                                bottomTeam={match.bottomTeam}
                                                topScores={match.topScores}
                                                bottomScores={match.bottomScores}
                                                winnerTeamIndex={match.winnerTeamIndex}
                                                inGrid={true}
                                                match={match}
                                                tourId={tournament.length - 1}
                                                matchId={mid}
                                            />
                                        </foreignObject>)}

                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
