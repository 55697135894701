import { useEffect, useLayoutEffect, useRef } from "react";

import Hls from 'hls.js';
import axios from "axios";

import styles from './styles.module.scss'

interface IVideoPlayerProps {
    width?: number;
    height?: number;
}

let useEffectIsTriggered = false;

export const VideoPlayer = ({operatorId}: any) => {
    const iOS = () => {
        return (/iPad|iPhone|iPod/.test(navigator.userAgent))
    }

    const ifYouTubeLink = (url: string) => {
        return url.includes("youtube.com");
    }

    const link = 'http://www.youtube.com/embed/BYhDKfQJXNI?si=sWM_7YL-H46JUJaB';

    const isYouTubeLink = ifYouTubeLink(link);

    const containerRef = useRef<any>(null);

    // вернуть как появится видео

    // const url = 'http://127.0.0.1:8080/';
    //
    // const isSupported = Hls.isSupported();
    //
    // axios.get(url + 'list').then((res) => {
    //     const src = url + res.data[0].uri;
    //
    //     if (iOS() && ref.current?.canPlayType('application/vnd.apple.mpegurl')) {
    //         ref.current.src = src;
    //         return;
    //     }
    //
    //     if (isSupported && ref.current !== null) {
    //         const hls = new Hls();
    //         hls.loadSource(src);
    //         hls.attachMedia(ref.current);
    //     }
    //
    // });

    useEffect(() => {

        if(!containerRef.current || !operatorId){
            return
        }

        useEffectIsTriggered = true

        const config = {
            El: containerRef.current,
            Source: "xsport" + operatorId,
            Autoplay: true,
            App: "4000",
            AppVer: "1",
            UseCinemaAPI: false,
            Quality: 1
            };
        // @ts-ignore
        var player = (window["VideoCore_WS"] as any).VideoPlayerCore.Create(config);
    }, [containerRef, operatorId])

    // if (isYouTubeLink) {
    //     return <iframe
    //         width="100%"
    //         height="100%"
    //         src={link}
    //         title="YouTube video player"
    //         frameBorder="0"
    //         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    //         allowFullScreen
    //     />

    // }


    return (
        // <div className={styles.VideoContainer} ref={containerRef} id={'videopl'}></div>
        <div className={styles.VideoContainer}>
            <iframe
                src={`https://streamsport365.com/player/op/${operatorId}`}
            >
            </iframe>
        </div>
        
        // <video
        //     height="100%"
        //     width="100%"
        //     id="video"
        //     ref={ref}
        //     autoPlay={true}
        //     playsInline={true}
        // />
    );
}