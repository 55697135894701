import styles from './styles.module.scss';
import {StatisticsTab} from "entities/ui/statisticsTab/statisticsTab";
import {useEffect, useState} from "react";
import {Tabs} from "shared/tabs/tabs";
import {IStatsWidgetProps} from "./types";



export function StatsWidget({teams, tabs}: IStatsWidgetProps) {

    const [tab, setTab] = useState(0);

    const tabChanger = (index: number) => () => {
        setTab(index)
    };

    const tabsTitles = tabs.map((tab: any) => tab.name);

    return <div className={styles.statsWidget}>
        <Tabs tabs={tabsTitles} tabChanger={tabChanger} />
        <StatisticsTab teams={teams} stats={tabs[tab].stats} />
    </div>;
}