// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_newsItem__alYdt {
  display: none;
  border-bottom: 2px dashed rgba(41, 127, 176, 0.34);
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 12px;
  padding-top: 9px;
}
.styles_newsItem__alYdt span {
  cursor: pointer;
  color: rgba(70, 77, 82, 0.78);
  text-decoration: underline;
  margin-left: 9px;
}
@media (max-width: 768px) {
  .styles_newsItem__alYdt {
    display: block;
  }
}`, "",{"version":3,"sources":["webpack://./src/widgets/news-table/news-item/mobile/styles.module.scss","webpack://./src/app/styles/vars/media.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,kDAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;AADJ;AAGI;EACI,eAAA;EACA,6BAAA;EACA,0BAAA;EACA,gBAAA;AADR;ACPE;EDPF;IAmBQ,cAAA;EADN;AACF","sourcesContent":["@use 'app/styles/vars/media' as media;\n\n.newsItem {\n    display: none;\n    border-bottom: 2px dashed rgba(41, 127, 176, 0.34);\n    text-align: left;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: normal;\n    padding-bottom: 12px;\n    padding-top: 9px;\n\n    span {\n        cursor: pointer;\n        color: rgba(70, 77, 82, 0.78);\n        text-decoration: underline;\n        margin-left: 9px;\n    }\n\n    @include media.media-sm {\n        display: block;\n    }\n}","// Desktop-first\n\n@mixin media-xs {\n  @media (max-width: 576px) {\n    @content;\n  }\n}\n\n@mixin media-sm {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin media-md {\n  @media (max-width: 992px) {\n    @content;\n  }\n}\n\n@mixin media-lg {\n  @media (max-width: 1200px) {\n    @content;\n  }\n}\n\n@mixin media-xl {\n  @media (max-width: 1400px) {\n    @content;\n  }\n}\n\n// Mobile first\n\n@mixin media-min-sm {\n  @media (min-width: 576px) {\n    @content;\n  }\n}\n\n@mixin media-min-md {\n  @media (min-width: 768px) {\n    @content;\n  }\n}\n\n@mixin media-min-lg {\n  @media (min-width: 992px) {\n    @content;\n  }\n}\n\n@mixin media-min-xl {\n  @media (min-width: 1200px) {\n    @content;\n  }\n}\n\n@mixin media-min-xxl {\n  @media (min-width: 1400px) {\n    @content;\n  }\n}\n\n@mixin media-min-xxxl {\n  @media (min-width: 1700px) {\n    @content;\n  }\n}\n\n// Hover\n\n@mixin media-hover {\n  @media (hover: hover) {\n    &:hover {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newsItem": `styles_newsItem__alYdt`
};
export default ___CSS_LOADER_EXPORT___;
