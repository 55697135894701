import {Teams} from "./teams/teams";
import {StatisticsUnit} from "features/statisticsUnit";
import {IStatisticsUnitProps} from "features/statisticsUnit/types";
import styles from './styles.module.scss';
import {IStatisticsTabProps} from "./types";


export function StatisticsTab({teams, stats}: IStatisticsTabProps){
    const firstTeam = teams[0];
    const secondTeam = teams[1];

    const statsInfo = stats?.map((stat: IStatisticsUnitProps, index: number) => {
        return <StatisticsUnit
            key={index + stat.name + stat.firstSide.percent * 10 + stat.secondSide.percent * 100}
            name={stat.name}
            firstSide={stat.firstSide}
            secondSide={stat.secondSide}/>
    })

    return (<div>
        <Teams firstTeam={firstTeam} secondTeam={secondTeam}/>
        <div className={styles.statsWrapper}>
            {statsInfo}
        </div>
    </div>)
}