import styles from './styles.module.css';
import React from "react";
import {TMatch, TMatchScore} from "../../../../app/store/slices/match-beats-slice";

const GAME = 100;
const AD = 200;
const BREAKPOINT = 300;
const ACE = 1;
const DOUBLE_FAULTS = 2;

export interface IGameProps {
    game: TMatch;
}

export function BreakPointBall(first?: boolean){
    return <image height="14" width="14" href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjUwMjkgMTY1NSAxMi4xMjUgMTIuMTI1Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xMiB7CiAgICAgICAgZmlsbDogI2NjYzsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPGcgaWQ9Ikdyb3VwXzE2MDAyIiBkYXRhLW5hbWU9Ikdyb3VwIDE2MDAyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0MzExIDExNjUpIj4KICAgIDxwYXRoIGlkPSJQYXRoXzE1OTQyIiBkYXRhLW5hbWU9IlBhdGggMTU5NDIiIGNsYXNzPSJjbHMtMTIiIGQ9Ik0zNDYuMDQ1LDc2LjVhNS42MzIsNS42MzIsMCwwLDAsMCw4LjI0NSw2LjE0Myw2LjE0MywwLDAsMCwxLjYtNC4xMjJBNiw2LDAsMCwwLDM0Ni4wNDUsNzYuNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM4Mi40OCA0MTUuNDM5KSIvPgogICAgPHBhdGggaWQ9IlBhdGhfMTU5NTIiIGRhdGEtbmFtZT0iUGF0aCAxNTk1MiIgY2xhc3M9ImNscy0xMiIgZD0iTTEuNiw3Ni41QTYuMTQzLDYuMTQzLDAsMCwwLDAsODAuNjIyYTYsNiwwLDAsMCwxLjYsNC4xMjJBNS41MjQsNS41MjQsMCwwLDAsMy40LDgwLjYyMiw1LjUyNCw1LjUyNCwwLDAsMCwxLjYsNzYuNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDcxOCA0MTUuNDM5KSIvPgogICAgPHBhdGggaWQ9IlBhdGhfMTU5NjIiIGRhdGEtbmFtZT0iUGF0aCAxNTk2MiIgY2xhc3M9ImNscy0xMiIgZD0iTTg0Ljc0NSwxLjZhNi4xMSw2LjExLDAsMCwwLTguMjQ1LDAsNS45NjQsNS45NjQsMCwwLDEsMS45NCw0LjQ2Miw1Ljk2NCw1Ljk2NCwwLDAsMS0xLjk0LDQuNDYyLDUuOTE3LDUuOTE3LDAsMCwwLDQuMTIyLDEuNiw2LjE0Myw2LjE0MywwLDAsMCw0LjEyMi0xLjZBNS45NjQsNS45NjQsMCwwLDEsODIuOCw2LjA2Miw1Ljk2NCw1Ljk2NCwwLDAsMSw4NC43NDUsMS42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjQzLjQ0IDQ5MCkiLz4KICA8L2c+Cjwvc3ZnPgo=" y={first ? 48 : 88} x="0"></image>
}

export function DoubleFaultsBall(first?: boolean){
    return <image height="14" width="14" href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMi4xMjUiIGhlaWdodD0iMTIuMTI1IiB2aWV3Qm94PSIwIDAgMTIuMTI1IDEyLjEyNSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2Q5MDgwODt9PC9zdHlsZT48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTExMjYgLTI0MjYpIj48cGF0aCBjbGFzcz0iYSIgZD0iTTM0Ni4wNDUsNzYuNWE1LjYzMiw1LjYzMiwwLDAsMCwwLDguMjQ1LDYuMTQzLDYuMTQzLDAsMCwwLDEuNi00LjEyMkE2LDYsMCwwLDAsMzQ2LjA0NSw3Ni41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzkwLjQ4IDIzNTEuNDM5KSIvPjxwYXRoIGNsYXNzPSJhIiBkPSJNMS42LDc2LjVBNi4xNDMsNi4xNDMsMCwwLDAsMCw4MC42MjJhNiw2LDAsMCwwLDEuNiw0LjEyMkE1LjUyNCw1LjUyNCwwLDAsMCwzLjQsODAuNjIyLDUuNTI0LDUuNTI0LDAsMCwwLDEuNiw3Ni41WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEyNiAyMzUxLjQzOSkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTg0Ljc0NSwxLjZhNi4xMSw2LjExLDAsMCwwLTguMjQ1LDAsNS45NjQsNS45NjQsMCwwLDEsMS45NCw0LjQ2Miw1Ljk2NCw1Ljk2NCwwLDAsMS0xLjk0LDQuNDYyLDUuOTE3LDUuOTE3LDAsMCwwLDQuMTIyLDEuNiw2LjE0Myw2LjE0MywwLDAsMCw0LjEyMi0xLjZBNS45NjQsNS45NjQsMCwwLDEsODIuOCw2LjA2Miw1Ljk2NCw1Ljk2NCwwLDAsMSw4NC43NDUsMS42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTA1MS40MzkgMjQyNikiLz48L2c+PC9zdmc+" y={first ? 48 : 88} x={0}></image>
}

export function AceBall(first?: boolean){
    return <svg style={{fill: '#ff9100'}} y={first ? 48 : 84} xmlns="http://www.w3.org/2000/svg" width="19.469" height="15.25" viewBox="0 0 19.469 15.25"><g transform="translate(-1046 -2397.875)"><g transform="translate(-80 -25)"><path className="a" d="M346.045,76.5a5.632,5.632,0,0,0,0,8.245,6.143,6.143,0,0,0,1.6-4.122A6,6,0,0,0,346.045,76.5Z" transform="translate(790.48 2351.439)"/><path className="a" d="M1.6,76.5A6.143,6.143,0,0,0,0,80.622a6,6,0,0,0,1.6,4.122A5.524,5.524,0,0,0,3.4,80.622,5.524,5.524,0,0,0,1.6,76.5Z" transform="translate(1126 2351.439)"/><path className="a" d="M84.745,1.6a6.11,6.11,0,0,0-8.245,0,5.964,5.964,0,0,1,1.94,4.462,5.964,5.964,0,0,1-1.94,4.462,5.917,5.917,0,0,0,4.122,1.6,6.143,6.143,0,0,0,4.122-1.6A5.964,5.964,0,0,1,82.8,6.062,5.964,5.964,0,0,1,84.745,1.6Z" transform="translate(1051.439 2426)"/></g><g transform="matrix(0.999, 0.052, -0.052, 0.999, 655.357, 1830.992)"><path className="a" d="M4.869.1.1,4.869a.33.33,0,1,0,.467.467L5.336.564A.33.33,0,0,0,4.869.1Z" transform="translate(433.11 544.962) rotate(9)"/><path className="a" d="M.568.1A.33.33,0,0,0,.1.1L.1.1a.33.33,0,0,0,.47.464L.57.562A.33.33,0,0,0,.568.1Z" transform="translate(438.592 545.145) rotate(9)"/><path className="a" d="M.33,2.554a.33.33,0,0,0,.233-.1L2.457.564A.33.33,0,0,0,1.99.1L.1,1.99a.33.33,0,0,0,.233.564Z" transform="translate(431.438 546.211) rotate(9)"/><path className="a" d="M3.324.1a.33.33,0,0,0-.467,0L.1,2.857a.33.33,0,1,0,.467.467L3.324.564A.33.33,0,0,0,3.324.1Z" transform="translate(433.935 548.608) rotate(9)"/><path className="a" d="M1.288.1.1,1.288a.33.33,0,0,0,.467.467L1.755.564A.33.33,0,0,0,1.288.1Z" transform="translate(433.638 552.682) rotate(9)"/><path className="a" d="M.1.1.1.1A.33.33,0,0,0,.564.569L.569.564A.33.33,0,0,0,.1.1Z" transform="translate(435.583 552.305) rotate(9)"/></g></g></svg>
}

const scoreToTextTranslator = {
    [GAME]: 'Game',
    [AD]: 'ad',
    // @ts-ignore
    get(property: number){
        // @ts-ignore
        return (this.hasOwnProperty(property) ? this[property] : property) as number;
    }
}   

const scoreToBallTranslator = {
    [BREAKPOINT]: BreakPointBall,
    [ACE]: AceBall,
    [DOUBLE_FAULTS]: DoubleFaultsBall,
    // @ts-ignore
    get(property: number, first: boolean){
        // @ts-ignore
        return this.hasOwnProperty(property) ? this[property](first) : undefined
    }
}

export function MatchBeatBackboneLine({blockWidth}: any){
    return <>
        <line
            x1="0"
            x2={blockWidth}
            y1="32"
            y2="32"
            className="tick-lines"
            strokeDasharray="2 2"
            strokeWidth="1"></line>
        <line x1="0" x2={blockWidth} y1="47" y2="47" className="tick-lines" strokeDasharray="2 2"
              strokeWidth="1"></line>
        <line x1="0" x2={blockWidth} y1="118" y2="118" className="tick-lines" strokeDasharray="2 2"
              strokeWidth="1"></line>
        <line x1="0" x2={blockWidth} y1="103" y2="103" className="tick-lines" strokeDasharray="2 2"
              strokeWidth="1"></line>
    </>
}

export function FirstPlayerHoldsRacket({holds}: any){
    return <>{holds === 1 && <image id="info-racket-1" height="20" width="20"
                                         href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC43MzciIGhlaWdodD0iMjIuNzE0IiB2aWV3Qm94PSIwIDAgMTguNzM3IDIyLjcxNCI+CiAgPGcgaWQ9Ikdyb3VwXzI2MTEiIGRhdGEtbmFtZT0iR3JvdXAgMjYxMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM2MC42MTMgLTYzNy4xOTUpIj4KICAgIDxwYXRoIGlkPSJQYXRoXzI0MzUiIGRhdGEtbmFtZT0iUGF0aCAyNDM1IiBkPSJNMjMzLjE1OCw1LjgwN2MwLTMuMi0yLjE4NS01LjgwNy00Ljg3LTUuODA3cy00Ljg3LDIuNi00Ljg3LDUuODA3YTYuMjExLDYuMjExLDAsMCwwLDEuOTg4LDQuNjdjLjAwNi4wMDYuMDA5LjAxNS4wMTUuMDJzMS43MTIsMS41NTQsMS43MTIsMy4yYS4zNzEuMzcxLDAsMCwwLC4wMzMuMTUxczAsLjAwNywwLC4wMTFWMjAuMjNhLjM3NS4zNzUsMCwwLDAsLjM3NS4zNzVoMS41YS4zNzUuMzc1LDAsMCwwLC4zNzUtLjM3NVYxMy44NjFhLjM1Ny4zNTcsMCwwLDAtLjAxNi0uMDgxLjM1Ny4zNTcsMCwwLDAsLjAxNi0uMDgxYzAtMS42MzksMS43NTItMy4xOTQsMS43Ny0zLjIxYS4zNjIuMzYyLDAsMCwwLC4wNTUtLjA3M0E2LjIzMiw2LjIzMiwwLDAsMCwyMzMuMTU4LDUuODA3Wm0tOC45OTEsMGMwLTIuNzg5LDEuODQ5LTUuMDU3LDQuMTIxLTUuMDU3czQuMTIxLDIuMjY5LDQuMTIxLDUuMDU3LTEuODQ5LDUuMDU3LTQuMTIxLDUuMDU3YTMuNTg5LDMuNTg5LDAsMCwxLTIuMy0uODY2bC0uMDYzLS4wNTksMCwwQTUuNDYyLDUuNDYyLDAsMCwxLDIyNC4xNjcsNS44MDdabTQuNTA4LDcuNjhoLS44YTQuNTM5LDQuNTM5LDAsMCwwLS43MTMtMi4wMzcsMy45NzIsMy45NzIsMCwwLDAsMi4yNDgsMEE0LjQ2NSw0LjQ2NSwwLDAsMCwyMjguNjc1LDEzLjQ4NloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3Ny40MyA1MjUuNDg2KSByb3RhdGUoMzApIiBmaWxsPSIjNDY5RkVFIi8+CiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzEzODAiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDEzODAiIHdpZHRoPSIwLjc0OSIgaGVpZ2h0PSI1LjYxOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzY3LjY5MSA2NTEuNzcxKSByb3RhdGUoMzApIiBmaWxsPSIjMEYxNzQ1Ii8+CiAgPC9nPgo8L3N2Zz4K"
                                         y="55" x="24"></image>}</>
}

export function SecondPlayerHoldsRacket({holds}: any){
    return <>{holds === 2 && <image id="info-racket-1" height="20" width="20"
                                    href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOC43MzciIGhlaWdodD0iMjIuNzE0IiB2aWV3Qm94PSIwIDAgMTguNzM3IDIyLjcxNCI+CiAgPGcgaWQ9Ikdyb3VwXzI2MTEiIGRhdGEtbmFtZT0iR3JvdXAgMjYxMSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM2MC42MTMgLTYzNy4xOTUpIj4KICAgIDxwYXRoIGlkPSJQYXRoXzI0MzUiIGRhdGEtbmFtZT0iUGF0aCAyNDM1IiBkPSJNMjMzLjE1OCw1LjgwN2MwLTMuMi0yLjE4NS01LjgwNy00Ljg3LTUuODA3cy00Ljg3LDIuNi00Ljg3LDUuODA3YTYuMjExLDYuMjExLDAsMCwwLDEuOTg4LDQuNjdjLjAwNi4wMDYuMDA5LjAxNS4wMTUuMDJzMS43MTIsMS41NTQsMS43MTIsMy4yYS4zNzEuMzcxLDAsMCwwLC4wMzMuMTUxczAsLjAwNywwLC4wMTFWMjAuMjNhLjM3NS4zNzUsMCwwLDAsLjM3NS4zNzVoMS41YS4zNzUuMzc1LDAsMCwwLC4zNzUtLjM3NVYxMy44NjFhLjM1Ny4zNTcsMCwwLDAtLjAxNi0uMDgxLjM1Ny4zNTcsMCwwLDAsLjAxNi0uMDgxYzAtMS42MzksMS43NTItMy4xOTQsMS43Ny0zLjIxYS4zNjIuMzYyLDAsMCwwLC4wNTUtLjA3M0E2LjIzMiw2LjIzMiwwLDAsMCwyMzMuMTU4LDUuODA3Wm0tOC45OTEsMGMwLTIuNzg5LDEuODQ5LTUuMDU3LDQuMTIxLTUuMDU3czQuMTIxLDIuMjY5LDQuMTIxLDUuMDU3LTEuODQ5LDUuMDU3LTQuMTIxLDUuMDU3YTMuNTg5LDMuNTg5LDAsMCwxLTIuMy0uODY2bC0uMDYzLS4wNTksMCwwQTUuNDYyLDUuNDYyLDAsMCwxLDIyNC4xNjcsNS44MDdabTQuNTA4LDcuNjhoLS44YTQuNTM5LDQuNTM5LDAsMCwwLS43MTMtMi4wMzcsMy45NzIsMy45NzIsMCwwLDAsMi4yNDgsMEE0LjQ2NSw0LjQ2NSwwLDAsMCwyMjguNjc1LDEzLjQ4NloiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE3Ny40MyA1MjUuNDg2KSByb3RhdGUoMzApIiBmaWxsPSIjNDY5RkVFIi8+CiAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlXzEzODAiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDEzODAiIHdpZHRoPSIwLjc0OSIgaGVpZ2h0PSI1LjYxOSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzY3LjY5MSA2NTEuNzcxKSByb3RhdGUoMzApIiBmaWxsPSIjMEYxNzQ1Ii8+CiAgPC9nPgo8L3N2Zz4K"
                                    y="75" x="24"></image>}</>
}

export function Game({game}: IGameProps){

    const blockWidth = 55 + 35 * game.scores.length;

    return <div className={styles.GameSvgContainer} style={{
      width: blockWidth,
    }}><svg className="infy-mb-game-bg game-wrapper   " style={{height: '154', width: '100%'}}>
        <line
            className="mid-horz-line"
            x1="0"
            x2="221"
            y1="75"
            y2="75"
            fill="white"
            strokeWidth="1">

        </line>
        <MatchBeatBackboneLine blockWidth={blockWidth}/>
        <FirstPlayerHoldsRacket holds={game.holds} />
        <SecondPlayerHoldsRacket holds={game.holds} />
        {game.scores.map((score: TMatchScore, gid: number) => {

            const scoreAdditionsTop = scoreToBallTranslator.get(score[4] || 0, true)
            const scoreAdditionsBottom = scoreToBallTranslator.get(score[5] || 0, false)

            const rectangleTopShow = scoreAdditionsTop === score[4]
            const rectangleBottomShow = scoreAdditionsBottom === score[5]

            return <g key={gid} className="point-plot-wrapper  infy-mb-point " transform={`translate(${51 + 35 * gid},0)`}>
                        <g className="point-wrapper " transform="translate(0,0)">
                            {rectangleTopShow && <rect x="3" y="52.5" width="6" height="7.5" className={`bar ${score[2] ? 'team1-bar' : 'lost-bar'}`} rx="0" ry="0"></rect>}
                            <text id="score" className="infy-text infy-bold score-text  " x="6" y="73">
                                {(scoreToTextTranslator.get(score[0]) === "Game" && game.isTieBreak === true && Number(game.scores[gid-1][0]) + 1) || scoreToTextTranslator.get(score[0])}
                            </text>
                            {score.length > 4 && score[4] && scoreToBallTranslator.get(score[4], true)}
                        </g>
                        <g className="point-wrapper " transform="translate(0,0)">
                            {rectangleBottomShow && <rect x="3" y="90" width="6" height="7.5" className={`bar ${score[3] ? 'team2-bar' : 'lost-bar'}`} rx="0" ry="0"></rect>}
                            {scoreToTextTranslator.get(score[1])}
                            <text id="score" className="infy-text infy-bold score-text lost " x="6" y="86">
                                {(scoreToTextTranslator.get(score[1]) === "Game" && game.isTieBreak === true && Number(game.scores[gid-1][1]) + 1) || scoreToTextTranslator.get(score[1])}
                            </text>
                            {score.length > 4 && score[5] && scoreToBallTranslator.get(score[5], false)}
                        </g>
                    </g>
        })}
    </svg></div>
}
