import {TournamentCard} from "widgets/tournament-card/TournamentCard";
import styles from './styles.module.scss'
import { FormattedMessage } from "react-intl";

export function MobileOnlineGrid({matchesOnline}: any){

    if(matchesOnline.length === 0){
      return <div className={styles.MobileOnlineGridWrapper}>
        <FormattedMessage id={'noMatchesOnline'}/>
      </div>
    }

    return <div className={styles.MobileOnlineGridWrapper}>{matchesOnline.map((match: any, mid: number) => <TournamentCard
            className={styles.OnlineCard}
            key={mid}
            topTeam={match.topTeam}
            bottomTeam={match.bottomTeam}
            topScores={match.topScores}
            bottomScores={match.bottomScores}
            winnerTeamIndex={match.winnerTeamIndex}
            inGrid={false}
            match={match}
            cardType={1}
        />)}</div>

}
