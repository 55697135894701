import React from "react"
import style from './style.module.scss'
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router-dom"
import classNames from "classnames"

type TArgButtonMatshSelect = {
    mode: number,
    setMode: React.Dispatch<React.SetStateAction<number>>,
    clickNavigateMatch: string
}

export const ButtonMatshSelect = ({
    mode,
    setMode,
    clickNavigateMatch
}: TArgButtonMatshSelect): JSX.Element => {
    const navigate = useNavigate()
    return (
        <div className={style.All}>
            <div className={mode === 0 ? classNames(style.Button, style.ButtonActive) : style.Button} onClick={() => {
                setMode(0)
                if(clickNavigateMatch) {
                    navigate(`/match/${clickNavigateMatch}`)
                }
            }}><FormattedMessage id={'match'}/></div>
            <div className={mode === 1 ? classNames(style.Button, style.ButtonActive) : style.Button} onClick={() => setMode(1)}><FormattedMessage id={'players'}/></div>
        </div>
    )
}