import styles from './styles.module.scss'
import {ReactComponent as ReactLogo} from 'asserts/logo.svg';
import { Link } from "react-router-dom";


export const Logo = () => {
    return <Link to='/'>
        <div className={styles.logo}>
            <ReactLogo/>
            <p className={styles.logo__text}>ATT</p>
        </div>
    </Link>
}