import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    title: '',
}

export const pageInfoSlice = createSlice({
    name: 'pageInfo',
    initialState,
    reducers: {
        setTitle: (state: any, action: any) => {
            state.title = action.payload;
        },
    } // todo
});

export const {
    setTitle,
} = pageInfoSlice.actions;

export default pageInfoSlice.reducer;
