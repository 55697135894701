// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_All__p29\\+7 {
  margin-top: 25px;
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.style_Button__RRi-d {
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  height: 40px;
  width: 135px;
  border: 1px solid #297FB0;
  background-color: transparent;
  color: #004C77;
}

.style_Button__RRi-d:hover {
  box-shadow: 0.5px 0.5px 5px rgba(0, 0, 0, 0.3);
  transition: 50ms;
}

.style_ButtonActive__ynjb2 {
  border: 1px solid #004C77;
  background-color: #004C77;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/widgets/buttons/buttonMatchSelect/style.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AACJ;;AACA;EACI,yBAAA;UAAA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,yBAAA;EACA,6BAAA;EACA,cAAA;AAEJ;;AACA;EACI,8CAAA;EACA,gBAAA;AAEJ;;AACA;EACI,yBAAA;EACA,yBAAA;EACA,YAAA;AAEJ","sourcesContent":[".All {\n    margin-top: 25px;\n    margin-left: 25px;\n    display: flex;\n    flex-direction: row;\n    justify-content: start;\n    align-items: center;\n    gap: 10px\n}\n.Button {\n    user-select: none;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-bottom: 2px;\n    height: 40px;\n    width: 135px;\n    border: 1px solid #297FB0;\n    background-color: transparent;\n    color: #004C77;\n}\n\n.Button:hover {\n    box-shadow: 0.5px 0.5px 5px rgba(0, 0, 0, 0.3);\n    transition: 50ms;\n}\n\n.ButtonActive {\n    border: 1px solid #004C77;\n    background-color: #004C77;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"All": `style_All__p29+7`,
	"Button": `style_Button__RRi-d`,
	"ButtonActive": `style_ButtonActive__ynjb2`
};
export default ___CSS_LOADER_EXPORT___;
