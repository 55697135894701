// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_header__erSg- {
  padding-left: 25px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #004C77;
  text-align: start;
  font-weight: 800;
  border-bottom: 2px dotted rgba(205, 221, 231, 0.8549019608);
  border-top: 2px dotted rgba(205, 221, 231, 0.8549019608);
}

.style_NoMatch__EZjtB {
  padding-left: 25px;
  color: #004C77;
  text-align: start;
}`, "",{"version":3,"sources":["webpack://./src/pages/match-page/modeWidgets/modePlayers/matchesWidget/style.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qCAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,2DAAA;EACA,wDAAA;AACJ;;AAEA;EACI,kBAAA;EACA,cAAA;EACA,iBAAA;AACJ","sourcesContent":[".header {\n    padding-left: 25px;\n    font-family: 'Montserrat', sans-serif;\n    font-size: 16px;\n    color: #004C77;\n    text-align: start;\n    font-weight: 800;\n    border-bottom: 2px dotted #cddde7da;\n    border-top: 2px dotted #cddde7da;\n}\n\n.NoMatch {\n    padding-left: 25px;\n    color: #004C77;\n    text-align: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `style_header__erSg-`,
	"NoMatch": `style_NoMatch__EZjtB`
};
export default ___CSS_LOADER_EXPORT___;
