import { TournamentCard } from "../../tournament-card/TournamentCard";

import {
    GRID_MATCH_BLOCK_SIZE,
    GRID_FIRST_BLOCK_HEIGHT,
    GRID_COLS_OFFSET,
} from './constants'

export function MatchCard({
    x, y, match, tourId, matchId, cardType
}: any){

    return  <foreignObject
        x={x}
        y={y}
        width={GRID_MATCH_BLOCK_SIZE} height={GRID_FIRST_BLOCK_HEIGHT}
    >
        <TournamentCard
            topTeam={match.topTeam}
            bottomTeam={match.bottomTeam}
            topScores={match.topScores}
            bottomScores={match.bottomScores}
            winnerTeamIndex={match.winnerTeamIndex}
            inGrid={true}
            match={match}
            tourId={tourId}
            matchId={matchId}
            cardType={cardType}
        /></foreignObject>
    }