// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_header__NKXOI {
  width: 425px;
  border-bottom: 1px solid rgb(41, 127, 176);
  color: rgb(25, 86, 121);
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0;
  padding-bottom: 2px;
  padding-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/tournament-grid/Schedule/Tour/header/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,0CAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;AACF","sourcesContent":[".header {\n  width: 425px;\n  border-bottom: 1px solid rgb(41, 127, 176);\n  color: rgb(25, 86, 121);\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 17px;\n  letter-spacing: 0;\n  padding-bottom: 2px;\n  padding-top: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__NKXOI`
};
export default ___CSS_LOADER_EXPORT___;
