import styles from "./styles.module.scss";
import {TournamentCard} from "../../tournament-card/TournamentCard";
import {ReactComponent as Arrows} from './images/arrows.svg';
import {ReactComponent as Close} from './images/close.svg';
import { FormattedMessage } from "react-intl";


export const OnlineMatches = ({ matchesOnline, onlineMatchesAside, setOnlineMatchesAside }: any) => {
    return <>
        {onlineMatchesAside ? <div className={styles.OnlineMatches}>
        <div className={styles.OnlineMatchesHeader}>
            <p>{matchesOnline.length > 0 ? <FormattedMessage id={'matchesOnline'}/> : <FormattedMessage id={'noMatchesOnline'}/>}</p>
            <Close onClick={() => setOnlineMatchesAside(false)}/>
        </div>
        {matchesOnline.map((match: any, mid: number) => <TournamentCard
            className={styles.OnlineCard}
            key={mid}
            topTeam={match.topTeam}
            bottomTeam={match.bottomTeam}
            topScores={match.topScores}
            bottomScores={match.bottomScores}
            winnerTeamIndex={match.winnerTeamIndex}
            inGrid={true}
            match={match}
            cardType={1}
            tourId={match.tourId}
            matchId={match.matchId}
        />)}
    </div> :
            <div className={styles.curtain} onClick={() => setOnlineMatchesAside(true)}>
                <p>
                    <FormattedMessage id={'matchesOnline'}/>
                </p>
                <Arrows/>
            </div>}
    </>
}
