// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_statsWrapper__p-pM- {
  padding-bottom: 16px;
}
.styles_statsWrapper__p-pM- > div:first-child {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/entities/ui/statisticsTab/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;AACF;AAEI;EACE,iBAAA;AAAN","sourcesContent":[".statsWrapper {\n  padding-bottom: 16px;\n\n  > div {\n    &:first-child {\n      padding-top: 10px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statsWrapper": `styles_statsWrapper__p-pM-`
};
export default ___CSS_LOADER_EXPORT___;
