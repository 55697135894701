import {Header} from "../../../shared/header";
import Navigation from "../../../features/navigation/navigation";
import styles from './styles.module.scss'
import {Footer} from "../../../widgets/footer/footer";

import {
    Outlet,
} from 'react-router-dom';
import img from "../../../pages/players-page/4ac44e22d195ef38c6e09758f232a99f.png";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ReactComponent as SearchIcon} from './images/seach.svg';
import {ApiProvider} from "../../api/api-provider";
import _ from "lodash";
import { FormattedMessage, useIntl } from "react-intl";


export const PlayersLayout = ({children}: any) => {
    const intl = useIntl()
    const api = ApiProvider();

    const [data, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);

    const [name, setName] = useState('');


    const firstFetching = async (name: string = '', page = 0) => {
        setIsLoading(true);
        try {
            const response = await api.getPlayersData({name, page});
            setItems(response.players || []);
            setPage(1);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    const fetchData = useCallback(async () => {
        if (isLoading) return;

        setIsLoading(true);

        api.getPlayersData({page, name}).then((res) => {
                // @ts-ignore
            setItems((prevItems) => [...prevItems, ...res?.players || []]);
            })
            .catch((err) => console.log(err));
        setPage((prevIndex) => prevIndex + 1);

        setIsLoading(false);
    }, [page, isLoading, name]);

    useEffect(() => {
        firstFetching();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } =
                document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 20) {
                fetchData();
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [fetchData]);


    function searchPlayer (value: string = '') {
        setPage(0);
        setName(value);
        firstFetching(value, 0)
    }


    const setServicesValueDebounced = useRef(_.debounce(searchPlayer, 1000));

    const onChangeInputHandler = (evt: any) => {
        const { value } = evt.target;

        setServicesValueDebounced.current(value)
    }

    return (<div className="App">
        <Header/>
        <Navigation/>
        <main>
            <div className={styles.background}>
                <img src={img} alt={''}/>
            </div>
            <div className={styles.body}>
                <div className={styles.header}>
                    <FormattedMessage id={'players'}/>
                </div>
                <div className={styles.search}>
                    <input type={'text'} placeholder={intl.formatMessage({id: "search"})} onChange={(e) => {
                        onChangeInputHandler(e)
                    }}/>
                    {/*<input type={'text'} placeholder={'Турнир'}/>*/}
                    {/*<input type={'text'} placeholder={'Страна'} onChange={(e) => {}}/>*/}
                    <button><SearchIcon/></button>
                </div>
                <>
                    {isLoading && <div> loading...</div>}
                    {data.length === 0 && <div className={styles.nothingShow}>
                        <FormattedMessage id={'noContent'}/>
                    </div>}
                    <Outlet context={[data]}/>
                </>
            </div>
        </main>
        <Footer/>
    </div>
)
}