import React from "react";
import style from './style.module.scss';

type TArgTournaments = {
	params?: any
}

export const Tournaments = ({
	params
}: TArgTournaments): JSX.Element => {
	return (
		<>
			<div>Tournaments</div>
		</>
	)
}