// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_tab__lJkNf {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/shared/tabs/tab/styles.module.scss"],"names":[],"mappings":"AACA;EACE,eAAA;AAAF","sourcesContent":["\n.tab {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tab": `styles_tab__lJkNf`
};
export default ___CSS_LOADER_EXPORT___;
