import { useState } from 'react';
import {EWinnerTeamIndex, IQualificationCardWinnerArgs} from "./types";
import styles from './styles.module.scss';
import {TeamList} from "../../entities/ui/TeamList/TeamList";
import {WinSign} from "../../features/WinSign/WinSign";
import {ScoresList} from "../../entities/ui/scores-list/ScoresList";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setMatchBeat } from "../../app/store/slices/match-beats-slice";
import classNames from "classnames";
import useDatetime from "./useDatetime";
import useTournamentGridData from "widgets/tournament-grid/UseTournamentGridData";

import {
    ELLIMINATION_TOURNAMENT_CARD,
    TOP_GRID_CARD,
    BOTTOM_GRID_CARD,
    FOR_SEVENTH_PLACE_CARD,
    FOR_FIFTH_PACE_CARD,
    HEMIFINAL,
    FINAL,
} from 'shared/constants'
import { FormattedMessage } from 'react-intl';

export function QualificationCardWinner({
                                   className,
                                   topTeam,
                                   bottomTeam,
                                   topScores,
                                   bottomScores,
                                   winnerTeamIndex,
                                   inGrid,
                                   match,
                                   fullwidth = false,
                                   datetime = '',
                                   matchId = -1,
                                   tourId = -1,
                                   cardType = ELLIMINATION_TOURNAMENT_CARD,
                                }: IQualificationCardWinnerArgs){

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { date, time } = useDatetime(datetime);
    const { tournamentId } = useTournamentGridData()

    const [awaitingAlert, setAwaitingAlert] = useState(false);

    const goToMatch = () => {
            if(match.topScores.length === 0 || match.bottomScores.length === 0){
              setAwaitingAlert(true)
              setTimeout(() => {
                setAwaitingAlert(false)
              }, 2000);
              return
            }
            navigate(`/match/${match.systemId}`)
    }

    const winnerPlayer = (match.team1Addition.includes("cup") && topTeam) || (match.team2Addition.includes("cup") && bottomTeam) || {players: []}
    const noByePlayer = (match.topTeam.players.length === 0 && bottomTeam) || (match.bottomTeam.players.length === 0 && topTeam) || {players: []}

    return <div className={classNames(styles.TournamentCard, {
        [styles.fullwidth]: fullwidth,
        [styles.awaitingAlert]: awaitingAlert,
    })} onClick={() => {}}>

        {time && date && <div className={styles.datetimeWrapper}>
            <div><label><FormattedMessage id={'date'}/>: </label>{ date } </div>
            <div><label><FormattedMessage id={'start'}/>: </label>{ time }</div>
        </div>}

        {!className && inGrid && <div className={styles.LeftConnector} >
            <div className={styles.LeftConnectorLine} />
        </div> }
        <div className={styles.TopLine}>
            {winnerPlayer.players.length !== 0 && <>
                <TeamList team={winnerPlayer}/>
                <WinSign showState={winnerPlayer.teamId ? true : false}/>
            </>}
            {tourId === 0 && noByePlayer.players.length !== 0 && <>
                <TeamList team={noByePlayer}/>
                <WinSign showState={true}/>
            </>}
        </div>
    </div>

}
