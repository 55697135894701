import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import { TLanguageSlice } from "./types";

const initialState: TLanguageSlice = {
    language: 'ru',
}

export const localLanguageSlice = createSlice({
    name: 'localLanguage',
    initialState,
    reducers: {
        setLanguage: (state: TLanguageSlice, action: PayloadAction<string>) => {
            return {
                ...state,
                language: action.payload
            }
        },
    }
});

export const { setLanguage } = localLanguageSlice.actions;
export default localLanguageSlice.reducer;
