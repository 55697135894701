import styles from "./styles.module.scss";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {ApiProvider} from "../../app/api/api-provider";
import empty from '../../images/empty.png'
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

type TOneNews = {
    id: string;
    banner: string;
    headers1: string;
    headers2: string;
    text: string;
    headers1EN: string;
    headers2EN: string;
    textEN: string;
}

export function AllNews({navi}: any) {
    const local = useSelector((state: any) => state.localLanguage.language)
    const api = ApiProvider();

    const [data, setItems] = useState<TOneNews[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);


    const firstFetching = async (page = 0) => {
        setIsLoading(true);
        try {
            const response = await api.getNewsData({page});

            setItems(response.allNews || []);
            setPage(1);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    const fetchData = useCallback(async () => {
        if (isLoading) return;

        setIsLoading(true);

        api.getNewsData({page}).then((res) => {
            setItems((prevItems) => [...prevItems, ...res?.allNews || []]);
        })
            .catch((err) => console.log(err));
        setPage((prevIndex) => prevIndex + 1);

        setIsLoading(false);
    }, [page, isLoading]);

    useEffect(() => {
        firstFetching();
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const { scrollTop, clientHeight, scrollHeight } =
                document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 20) {
                fetchData();
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [fetchData]);

    function goToNews(id:string){
        navi(`/news/${id}`);
    }


    return <div className={styles.allNews}>
        {data.map(row =>
            <div
                className={styles.blockNews}
                onClick={()=> goToNews(row.id)}
                key={row?.id}
            >
                <img src={row.banner || empty} alt={''} />
                <div className={styles.header}>
                    <div>{local === 'ru' ? row.headers1 : row.headers1EN}</div>
                    <div>{local === 'ru' ? row.headers2 : row.headers2EN}</div>
                    <div className={styles.showMore}>
                        <FormattedMessage id={'readMore'}/>
                    </div>
                </div>
            </div>
        )}
    </div>

}

export default AllNews
