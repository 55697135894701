import React, { useEffect, useState } from "react";
import style from './style.module.scss';
import { CountryBar } from "features/tournament-table/tournament-line/ui/country-bar/CountryBar";
import { TMatch, TTourMatchPlayerData } from "app/api/types";
import { MatchesList } from "pages/match-page/modeWidgets/modePlayers/matchesWidget/playerMatchesList";
import { useNavigate } from "react-router-dom";

type TArgResults = {
	tournamentsList: TTourMatchPlayerData[]
}

export const Results = ({
	tournamentsList
}: TArgResults): JSX.Element => {
	const navigate = useNavigate()
	const [ matchList, setMatchList ] = useState<any>([])

	useEffect(() => {
		setMatchList(tournamentsList.map((tour: any) => tour.matches).flat(1))
	},[tournamentsList])

	return (
		<div className={style.All}>
			<MatchesList matchesList={matchList} defoultCount={10} onClick={(match: TMatch) => navigate(`/match/${match._id}`)}/>
		</div>
	)
}