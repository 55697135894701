import React, { useEffect, useState } from "react"
import style from './style.module.scss'

type TSelect = {
    defaultValue: string,
    selects: {[key: string]: string},
    onChange: any,
    stylePage?: any
}

export const Select = ({
        defaultValue,
        selects,
        onChange,
        stylePage
    }: TSelect): JSX.Element => {
    const [active, setActive] = useState<boolean>(false)
    const [value, setValue] = useState<string>(defaultValue)

    useEffect(() => setValue(defaultValue), [defaultValue])

    return (
        <div style={stylePage} className={style.All} onClick={() => setActive(!active)}>
            <div>{value}</div>
            <div className={active ? style.svgActive : style.svgNoActive}>
                <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L8 7L15 1" stroke="#195679" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>

            <div className={active ? style.selects : style.none}>
                {Object.keys(selects).map((key: string) => {
                    if(key === value) return;
                    return (
                        <div onClick={() => {
                                setValue(key)
                                onChange(selects[key])
                            }}>
                            {key}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}