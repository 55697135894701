import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {Player} from "./player/request";
import { SelectModeButtons } from "./selectModeButtons";
import { useState } from "react";
import styles from './styles.module.scss'
import { SelectTypeMatchButtons } from "./selectTypeMatchButtons";
import { Profile } from "./modePage/profile";
import { Tournaments } from "./modePage/tournaments";
import { Results } from "./modePage/results";
import { apiProvider } from "app/api/api-provider";
import { TTourMatchPlayerData } from "app/api/types";

export function PlayerPage () {
    const params = useParams();
    const { id } = params;
    const [mode, setMode] = useState<number>(2)
    const [typeMaych, setTypeMaych] = useState<number>(0)
    const [playerTournaments, setPlayerTournaments] = useState<TTourMatchPlayerData[]>([])
    
    useEffect(() => {
        getMatchesPlayer()
    },[id])

    if (!id) {
        return <>Loading</>
    }

    const getMatchesPlayer = async() => {
        const res = await apiProvider.getTourMatchPlayerData(id)
        if(!res.tournaments) {
            return
        }
        setPlayerTournaments(res.tournaments)
    }

    const filterTournament = (tourList: TTourMatchPlayerData[]) => {
        return tourList.filter((tour: TTourMatchPlayerData) => {
            if(true){}
        })
    }

    return (
        <div className={styles.All}>
            <Player id={id}/>
            <SelectModeButtons setSelectMode={setMode} selectMode={mode}/>
            {/* <SelectTypeMatchButtons setSelectTypeMatch={setTypeMaych} selectTypeMatch={typeMaych}/> */}
            {mode === 0 && <Profile/>}
            {mode === 1 && <Tournaments/>}
            {mode === 2 && <Results tournamentsList={playerTournaments}/>}
        </div>
    )
}
export default PlayerPage;