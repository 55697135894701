import React, { useState } from "react";
import style from './style.module.scss'
import { CountryBar } from "features/tournament-table/tournament-line/ui/country-bar/CountryBar";
import { TMatch, TPlayer, TScore, TStatistics } from "app/api/types";
import { useIntl } from "react-intl";
import { TableFooter } from 'features/tournament-table/table-footer/TableFooter';

type TArgMatchesList = {
    onClick: any
    matchesList: TMatch[],
    defoultCount?: number,
}

export const MatchesList = ({
    onClick,
    matchesList,
    defoultCount = 5
}: TArgMatchesList): JSX.Element => {
    const [ countMatchList, setCountMatchList ] = useState<number>(defoultCount)

    const dateMatch = (dateMatsh: string) => {
        const date = new Date(parseInt(dateMatsh))
        return date.toLocaleDateString()
    }

    const scoreMatch = (matchData: TMatch) => {
        let countPlayer1 = 0 
        let countPlayer2 = 0
        matchData && matchData.team1Score.length !== 0 && matchData.team1Score.forEach((set: TScore, index: number) => {
            if(set.score > matchData.team2Score[index].score) {
                countPlayer1 += 1
            }
            if(set.score < matchData.team2Score[index].score) {
                countPlayer2 += 1
            }
        });
        return {
            score1: countPlayer1,
            score2: countPlayer2
        }
    }

    return (
        <div className={style.All}>
            {matchesList.length !== 0 && matchesList.map((match: TMatch, index: number) => {
                if (match.team1Names.length === 0 || match.team2Names.length === 0) {
                    return
                }
                if(index + 1 > countMatchList) {
                    return
                }
                return (
                    <div className={style.Match} onClick={() => onClick(match)}>
                        <div>{dateMatch(match.datetime)}</div>
                        <div className={style.Player}>
                            <div className={style.Name}>
                                <CountryBar countryName={''} flag={match.team1Names[0].country} />
                                <div>{match.team1Names[0].name}</div>
                            </div>
                            <div>
                                <div>{scoreMatch(match).score1}</div>
                                <div>
                                    {match.team1Score.map((scoreMatch: TScore) => 
                                        <div>{scoreMatch.score}</div>
                                    )}
                                </div>
                            </div>
                            
                        </div>
                        <div className={style.Player}>
                            <div className={style.Name}>
                                <CountryBar countryName={''} flag={match.team2Names[0].country} />
                                <div>{match.team2Names[0].name}</div>
                            </div>
                            <div>
                                <div>{scoreMatch(match).score2}</div>
                                <div>
                                    {match.team2Score.map((scoreMatch: TScore) => 
                                        <div>{scoreMatch.score}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            {countMatchList < matchesList.length && <TableFooter onClick={() => setCountMatchList(countMatchList + defoultCount)}/>}
        </div>
    )
}