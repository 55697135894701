// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_WinSign__VvKkD{
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/features/WinSign/styles.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".WinSign{\n    min-width: 25px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WinSign": `styles_WinSign__VvKkD`
};
export default ___CSS_LOADER_EXPORT___;
