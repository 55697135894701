
export enum ECourtTypeEnum {
    clay,
    hard,
    beach,
    grass,
}

export type TCountry = {
    countryName: string;
    flag: string;
}

export type TCourtType = {
    name: string;
    type: ECourtTypeEnum;
}

export interface ITournamenLineArgs {
    name: string;
    country: TCountry;
    courtType: TCourtType;
    live: boolean;
    tournamentId: string;
    dateOf: string;
    id: string;
    startDate?: string;
    completeDate?: string;
}
