import styles from './styles.module.scss'
import { useOutletContext } from "react-router-dom";
import {PlayerCard} from "entities/ui/player-card/PlayerCard";


export function PlayersPage () {
    // @ts-ignore
    const [data] = useOutletContext();



    return (
        <div className={styles.playersGrid}>
            {data.map((player: any) =>
               <PlayerCard player={player} key={player.id}/>
            )}
        </div>
    )
}

export default PlayersPage;