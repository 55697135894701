import styles from './styles.module.css';
import {IScoresListArgs} from "./types";
export function ScoresList({scores}: IScoresListArgs){
    return <div className={styles.ScoreList}>
        {scores.map((part, id) => <div key={id + part.score * 10 + (part.tie || 0) * 100 } className={styles.ScorePart}>
            <div className={styles.BaseScore}>
                {part.score}
                {part.tie !== 0 && part.tie !== undefined && <div className={styles.TieBreak}>{part.tie}</div>}
            </div>
        </div> )}
    </div>
} 