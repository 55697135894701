import './MainPage.scss';
import {Header} from "shared/header";
import {TournamentsTable} from "widgets/tournaments-table";
import {NewsTable} from "widgets/news-table/NewsTable";
import {Footer} from "widgets/footer/footer";
import styles from './styles.module.scss';
import Navigation from "features/navigation/navigation";
import {useNavigate} from "react-router-dom";
import { FormattedMessage } from 'react-intl';

/**
 * Тут должна происходить инициализация всех структур данных главной страницы
 * 
 * 1) Список промо-новостей
 * 2) Полный список турниров, которые должен отобразить сайт в виду требований к индексации поисковыми системами
 * 3) Требуется перевести сайт на SSR
 * 
 * Влияние SSR
 * 1) Вместо провайдеров будет передача данных через пропсы
 * 2) Вместо API будет прямое подключение к БД
 * 
 * Следовательно завершение разработки фронтенда должно быть выполнено на моках, 
 * либо на замещающих объектах построенных на апи провайдераъ
 * @returns 
 */

function MainPage() {



    const navigate = useNavigate()

  return (
      <>
          <div className={styles.MainContentBlock}>
              {/* <CurrentMatches {...mockMatchesData}/> */}
              {/* <BreadCrumbs /> */}
              {/* <div className={styles.NewsContainer}>
                <div className={styles.NewsHeader}>
                    Новости
                </div
            </div> */}
              {/* <Divider /> */}
              <div className={styles.NewsBlock}>
                  <h2><FormattedMessage id={'news'}/></h2>
                  <NewsTable navi={navigate}/>
              </div>
              <TournamentsTable live={{"$ne": 'complete'}}/>
          </div>
          <div className={'background-gradient'}/>
      </>
  );
}

export default MainPage;
