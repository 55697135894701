// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_VideoContainer__wmPrJ {
  min-height: 100%;
  min-width: 100%;
  position: relative;
  padding-bottom: calc(56.25% + 8px);
}
.styles_VideoContainer__wmPrJ iframe {
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/widgets/videoPlayer/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;EACA,kBAAA;EAEA,kCAAA;AAAJ;AAEI;EACI,gBAAA;EACA,eAAA;EAEA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;AADR","sourcesContent":[".VideoContainer{\n    min-height: 100%;\n    min-width: 100%;\n    position: relative;\n\n    padding-bottom: calc(56.25% + 8px);\n\n    iframe{\n        min-height: 100%;\n        min-width: 100%;\n\n        position: absolute;\n        top: 0;\n        bottom: 0;\n        left: 0;\n        right: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"VideoContainer": `styles_VideoContainer__wmPrJ`
};
export default ___CSS_LOADER_EXPORT___;
