import styles from './styles.module.scss';
import React, {useState, useEffect} from "react";
import {TournamentGridContainer} from "./desktop/TournamentGridContainer";
import {MobileTournament} from "./mobile/MobileTournament";

import useTournamentGridData from "./UseTournamentGridData";
import classNames from "classnames";
import {OnlineMatches} from "./onlineMatches/OnlineMatches";
import {Schedule} from "./Schedule/Schedule";
import { MainTournamentGridContainer } from './desktop/MainTournamentGridContainer';
import { MainMobileTournament } from './mobile/MainMobileTournament';
import {TournamentCard} from "widgets/tournament-card/TournamentCard";
import { MobileOnlineGrid } from './MobileOnlineGrid';
import { FormattedMessage, useIntl } from 'react-intl';

export function TournamentGrid(){
    const intl = useIntl()
    enum tabTypes {
        bracket, schedule, mobileSchedule 
    }

    const [onlineMatchesAside, setOnlineMatchesAside] = useState(false)

    const {
        tournament,
        matchesOnline,
        tournamentGridHeight,
        tournamentName,
        tournamentId,
        isEllimination,
    } = useTournamentGridData();

    useEffect(() => {
      console.log('tournament is updated', tournament)
    }, [tournament])

    const [activeTab, setAvtiveTab] = useState(0);

    const tabs = [
        {
            text: intl.formatMessage({id: 'tournamentGrid'}),
            value: tabTypes.bracket
        },
        {
            text: intl.formatMessage({id: 'schedule'}),
            value: tabTypes.schedule
        },
        {
            text: intl.formatMessage({id: 'matchesOnline'}),
            value: tabTypes.mobileSchedule
        },
    ]

    const changeTab = (index: number) => {
        setAvtiveTab(index)
    }

    if(!tournament){
        return <div>Loading..</div>
    }

    return (<div className={styles.tournaments}>
        <div className={styles.tabsWrapper}>

            <div className={classNames(styles.tab, {[styles.active]: activeTab === 0})} onClick={() => changeTab(0)}>
                <FormattedMessage id={'tournamentGrid'}/>
            </div>
            <div className={classNames([styles.tab, styles.ScheduleTab], {[styles.active]: activeTab === 1})} onClick={() => changeTab(1)}>
                <FormattedMessage id={'schedule'}/>
            </div>
            <div className={classNames([styles.tab, styles.InTabsMatchesOnline], {[styles.active]: activeTab === 2})} onClick={() => changeTab(2)}>
                <FormattedMessage id={'matchesOnline'}/>
            </div>
            {/* {tabs.map((tab: any, index: number) => {
                return <div key={index} className={classNames(styles.tab, {[styles.active]: activeTab === index})} onClick={() => changeTab(index)}>
                    {tab.text}
                </div>
            })} */}
        </div>


        {isEllimination && tabs[activeTab].value === tabTypes.bracket && <>
            <TournamentGridContainer
                tournament={tournament}
                tournamentGridHeight={tournamentGridHeight}
            />
            <MobileTournament tournament={tournament} tournamentName={tournamentName}/>
        </>}

        {!isEllimination && tabs[activeTab].value === tabTypes.bracket && <>
            <MainTournamentGridContainer
                tournament={tournament}
                tournamentGridHeight={tournamentGridHeight}
            />
            <MainMobileTournament tournament={tournament} tournamentName={tournamentName}/>
        </>}


        {tabs[activeTab].value === tabTypes.schedule && <>
            <Schedule
                tournament={tournament}
                tournamentName={tournamentName}
            />
        </>}

        {tabs[activeTab].value === tabTypes.mobileSchedule && matchesOnline && <MobileOnlineGrid matchesOnline={matchesOnline} />}
  
        <OnlineMatches
            matchesOnline={matchesOnline}
            onlineMatchesAside={onlineMatchesAside}
            setOnlineMatchesAside={setOnlineMatchesAside}
        />
    </div>)

}
