// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_LiveStreamSign__rxftU{
    color: #fff;
    background: red;
    padding: 5px 10px;
    border-radius: 5px;
    max-width: 50px;
    font-size: 10px;
}
.styles_LiveStreamSign__rxftU:before{
    content: '*';
    margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/features/tournament-table/tournament-line/ui/live-sign/styles.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,eAAe;AACnB;AACA;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".LiveStreamSign{\n    color: #fff;\n    background: red;\n    padding: 5px 10px;\n    border-radius: 5px;\n    max-width: 50px;\n    font-size: 10px;\n}\n.LiveStreamSign:before{\n    content: '*';\n    margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LiveStreamSign": `styles_LiveStreamSign__rxftU`
};
export default ___CSS_LOADER_EXPORT___;
