// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_statisticsLine__fkcUB {
  height: 10px;
  background: #C2CED3;
  width: 100%;
  display: flex;
}
.styles_statisticsLine__fkcUB > div {
  width: 50%;
  height: 100%;
  position: relative;
}
.styles_statisticsLine__fkcUB > div > div {
  height: 100%;
}
.styles_statisticsLine__fkcUB .styles_firstSide__bvyUS {
  position: absolute;
  top: 0;
  right: 0;
  background: #297FB0;
  opacity: 0.69;
}
.styles_statisticsLine__fkcUB .styles_secondSide__\\+pODi {
  background: #2483B9;
  opacity: 0.35;
  position: absolute;
  top: 0;
  left: 0;
}`, "",{"version":3,"sources":["webpack://./src/features/statisticsUnit/statisticsLine/styles.module.scss"],"names":[],"mappings":"AAAA;EACM,YAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;AACN;AACM;EACM,UAAA;EACA,YAAA;EACA,kBAAA;AACZ;AACY;EACM,YAAA;AAClB;AAGM;EACM,kBAAA;EACA,MAAA;EACA,QAAA;EACA,mBAAA;EACA,aAAA;AADZ;AAKM;EACM,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;AAHZ","sourcesContent":[".statisticsLine {\n      height: 10px;\n      background: #C2CED3;\n      width: 100%;\n      display: flex;\n\n      > div {\n            width: 50%;\n            height: 100%;\n            position: relative;\n\n            > div {\n                  height: 100%;\n            }\n      }\n\n      .firstSide {\n            position: absolute;\n            top: 0;\n            right: 0;\n            background: #297FB0;\n            opacity: 0.69;\n\n      }\n\n      .secondSide {\n            background: #2483B9;\n            opacity: 0.35;\n            position: absolute;\n            top: 0;\n            left: 0;\n      }\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statisticsLine": `styles_statisticsLine__fkcUB`,
	"firstSide": `styles_firstSide__bvyUS`,
	"secondSide": `styles_secondSide__+pODi`
};
export default ___CSS_LOADER_EXPORT___;
