import React, { useEffect, useState } from "react"
import { PlayersWidget } from "./playersWidget"
import { MatchesWidget } from "./matchesWidget"
import { TMatch, TStatistics } from "app/api/types"
import { match } from "assert"

type TArgModePlayer = {
    setStat: (statistics: TStatistics) => void,
    matchData: TMatch,
    setClickNavigateMatch: React.Dispatch<React.SetStateAction<string>>
}

export type TNowMatchData = {
    tourName?: string,
    tourId?: string,
    country?: string,
    match: TMatch
}

export const ModePlayer = ({
    setStat,
    matchData,
    setClickNavigateMatch
}: TArgModePlayer): JSX.Element => {
    const [nowMatchData, setNowMatchData] = useState<any>()
    useEffect(() => {
        setNowMatchData({
            match: matchData
        })
    },[])

    useEffect(() => {
        const matchExists = nowMatchData?.match?._id
        if(!matchExists) {
            return
        }
        setClickNavigateMatch(matchExists)
    },[nowMatchData])

    if(!nowMatchData) {
        return <></>
    }

    return (
        <>
            <PlayersWidget matchData={nowMatchData}/>
            <MatchesWidget matchData={matchData} setStat={setStat} setNowMatchData={setNowMatchData}/>
        </>
    )
}