// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Playfair+Display:ital,wght@1,500&family=Prata&family=Roboto:ital,wght@0,700;1,300&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_TeamList__dSq4S {
    background: var(--background-color);
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-top: 7px;

}
.styles_PlayerLine__GY\\+Fy{
    display: flex;
    gap: 21px;
    font-weight: 700;
    font-size: 14px;
    align-items: center;
    margin-bottom: 7px;
}
.styles_FlagBlock__rCzLU{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}
.styles_FlagBlock__rCzLU img {
    border: 1px solid var(--color-blue);
    margin-right: 10px;
}
.styles_ISO__wQGGf{
    color: #606060;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.styles_PlayerName__viBX3{
    color: #606060;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 11px;

}
.styles_Flag__6U6zg{
    display: flex;
    justify-content: center;
    align-items: center;
    transform: scale(1.5)
}
.styles_FlagBlock__rCzLU{
    height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/entities/ui/TeamList/styles.module.css"],"names":[],"mappings":"AAEA;IACI,mCAAmC;IACnC,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,gBAAgB;;AAEpB;AACA;IACI,aAAa;IACb,SAAS;IACT,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,mCAAmC;IACnC,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;;AAErB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB;AACJ;AACA;IACI,YAAY;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Playfair+Display:ital,wght@1,500&family=Prata&family=Roboto:ital,wght@0,700;1,300&display=swap');\n\n.TeamList {\n    background: var(--background-color);\n    display: flex;\n    flex-direction: column;\n    min-height: 100%;\n    padding-top: 7px;\n\n}\n.PlayerLine{\n    display: flex;\n    gap: 21px;\n    font-weight: 700;\n    font-size: 14px;\n    align-items: center;\n    margin-bottom: 7px;\n}\n.FlagBlock{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 2px;\n}\n.FlagBlock img {\n    border: 1px solid var(--color-blue);\n    margin-right: 10px;\n}\n.ISO{\n    color: #606060;\n    font-family: Montserrat;\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n}\n.PlayerName{\n    color: #606060;\n    font-family: Montserrat;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n    margin-left: 11px;\n\n}\n.Flag{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transform: scale(1.5)\n}\n.FlagBlock{\n    height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TeamList": `styles_TeamList__dSq4S`,
	"PlayerLine": `styles_PlayerLine__GY+Fy`,
	"FlagBlock": `styles_FlagBlock__rCzLU`,
	"ISO": `styles_ISO__wQGGf`,
	"PlayerName": `styles_PlayerName__viBX3`,
	"Flag": `styles_Flag__6U6zg`
};
export default ___CSS_LOADER_EXPORT___;
