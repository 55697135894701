import styles from "./styles.module.scss";
import React, {useEffect, useState} from "react";
import {PlayerCard} from "entities/ui/player-card/PlayerCard";
import {setTitle} from "app/store/slices/page-info-slice";
import {useDispatch} from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { apiProvider } from "app/api/api-provider";
import { TPlayer } from "app/api/types";

type TArgPlayer = {
    id: string
}
export function Player({
    id
}: TArgPlayer) {
    const [error, setError] = useState('');
    const [player, setPlayer] = useState<TPlayer | null>(null);
    const dispatch = useDispatch();
    const intl = useIntl()

    const getPlayer = async(playerId: string) => {
        const { player } = await apiProvider.getPlayerData({id: playerId})
        if(!player) {
            setError('Error')
            return
        }
        dispatch(setTitle(player.name))
        setPlayer(player)
    }

    useEffect(() => {
        if(!id) {
            return
        }
        getPlayer(id)
    }, [id]);

    const playerYears = (birthDate: string) => {
        const birthDatePlayer: number[] = birthDate.split('-').map(count => Number(count))

        let datePlayer = new Date(birthDatePlayer[0], birthDatePlayer[1] - 1, birthDatePlayer[2])

        let date = new Date()
        date.setFullYear(date.getFullYear() - datePlayer.getFullYear())
        date.setMonth(date.getMonth() - datePlayer.getMonth())
        date.setDate(date.getDay() - datePlayer.getDay())

        const stringYear = (num = date.getFullYear().toString().split('').reverse()[0]) => {
            let stringYear = ''
            if([0,5,6,7,8,9].indexOf(Number(num)) !== -1){
                stringYear = intl.formatMessage({id: "formatYear1"})
            }
            if([1].indexOf(Number(num)) !== -1){
                stringYear = intl.formatMessage({id: "formatYear2"})
            }
            if([2,3,4].indexOf(Number(num)) !== -1){
                stringYear = intl.formatMessage({id: "formatYear3"})
            }
            if([11,12,13,14].indexOf(Number(date.getFullYear())) !== -1){
                stringYear = intl.formatMessage({id: "formatYear1"})
            }

            return stringYear
        }

        return date.getFullYear() + " " + stringYear()
    }

    if (!player) return <></>;
    if (error) return <>{error}</>

    return <div className={styles.playerPage}>
        <PlayerCard player={player} isPlayerPage/>
        <div className={styles.info}>
            <div>{player.name}</div>
            <div className={styles.data}>
                <div>
                    <div><FormattedMessage id={'citizenship'}/>: </div>
                    <div>{player.country}</div>
                </div>
                <div>
                    <div><FormattedMessage id={'age'}/>: </div>
                    <div>{playerYears(player.birthDate)}</div>
                </div>
                <div>
                    <div><FormattedMessage id={'dateOfBirth'}/>: </div>
                    <div>{player.birthDate}</div>
                </div>
                <div>
                    <div><FormattedMessage id={'height'}/>: </div>
                    <div>{player.height}</div>
                </div>
                <div>
                    <div><FormattedMessage id={'weight'}/>: </div>
                    <div>{player.weight}</div>
                </div>
                <div>
                    <div><FormattedMessage id={'hand'}/>: </div>
                    <div>{player.plays}</div>
                </div>
            </div>
        </div>
    </div>
}

export default Player;
