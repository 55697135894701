// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_BreadCrumbs__dP71\\+ {
  display: flex;
  align-items: end;
}
@media (max-width: 992px) {
  .styles_BreadCrumbs__dP71\\+ {
    display: none;
  }
}
.styles_BreadCrumbs__dP71\\+ > a {
  color: #547F9C;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 240% */
  text-transform: uppercase;
  text-decoration: none;
}
.styles_BreadCrumbs__dP71\\+ .styles_thirdLvl__1uSpQ {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}`, "",{"version":3,"sources":["webpack://./src/entities/ui/breadcrumbs/styles.module.scss","webpack://./src/app/styles/vars/media.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,gBAAA;AADJ;ACYE;EDbF;IAKQ,aAAA;EAAN;AACF;AAEI;EACI,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,yBAAA;EACA,qBAAA;AAAR;AAGI;EACI,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;AADR","sourcesContent":["@use 'app/styles/vars/media' as media;\n\n.BreadCrumbs {\n    display: flex;\n    align-items: end;\n\n    @include media.media-md {\n        display: none;\n    }\n\n    > a {\n        color: #547F9C;\n        font-size: 10px;\n        font-style: normal;\n        font-weight: 500;\n        line-height: 24px; /* 240% */\n        text-transform: uppercase;\n        text-decoration: none;\n    }\n\n    .thirdLvl {\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        max-width: 130px;\n    }\n}","// Desktop-first\n\n@mixin media-xs {\n  @media (max-width: 576px) {\n    @content;\n  }\n}\n\n@mixin media-sm {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n@mixin media-md {\n  @media (max-width: 992px) {\n    @content;\n  }\n}\n\n@mixin media-lg {\n  @media (max-width: 1200px) {\n    @content;\n  }\n}\n\n@mixin media-xl {\n  @media (max-width: 1400px) {\n    @content;\n  }\n}\n\n// Mobile first\n\n@mixin media-min-sm {\n  @media (min-width: 576px) {\n    @content;\n  }\n}\n\n@mixin media-min-md {\n  @media (min-width: 768px) {\n    @content;\n  }\n}\n\n@mixin media-min-lg {\n  @media (min-width: 992px) {\n    @content;\n  }\n}\n\n@mixin media-min-xl {\n  @media (min-width: 1200px) {\n    @content;\n  }\n}\n\n@mixin media-min-xxl {\n  @media (min-width: 1400px) {\n    @content;\n  }\n}\n\n@mixin media-min-xxxl {\n  @media (min-width: 1700px) {\n    @content;\n  }\n}\n\n// Hover\n\n@mixin media-hover {\n  @media (hover: hover) {\n    &:hover {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BreadCrumbs": `styles_BreadCrumbs__dP71+`,
	"thirdLvl": `styles_thirdLvl__1uSpQ`
};
export default ___CSS_LOADER_EXPORT___;
