import React from "react";
import style from './style.module.scss'
import { FormattedMessage } from "react-intl";

type TArgSelectModeButtons = {
    setSelectMode: React.Dispatch<React.SetStateAction<number>>,
    selectMode: number
}

export const SelectModeButtons = ({
    setSelectMode,
    selectMode
}: TArgSelectModeButtons): JSX.Element => {

    return (
        <>
            <div className={style.selectMatches}>
                {/* <div className={selectMode === 0 ? style.ActiveSelectMatches : ''} onClick={() => setSelectMode(0)}>
                    <FormattedMessage id={'profile'}/>
                </div>
                <div className={selectMode === 1 ? style.ActiveSelectMatches : ''} onClick={() => setSelectMode(1)}>
                    <FormattedMessage id={'tournaments'}/>
                </div>
                <div className={selectMode === 2 ? style.ActiveSelectMatches : ''} onClick={() => setSelectMode(2)}>
                    <FormattedMessage id={'results'}/>
                </div> */}
                <div className={selectMode === 2 ? style.ActiveSelectMatches : ''} onClick={() => setSelectMode(2)}>
                    <FormattedMessage id={'matches'}/>
                </div>
            </div>
        </>
    )
}  