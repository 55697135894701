// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_selectMatches__FB2U\\+ {
  margin-left: 25px;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 20px;
}
.style_selectMatches__FB2U\\+ > div {
  width: max-content;
  height: max-content;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #004C77;
  font-weight: 800;
  border: 0;
}
.style_selectMatches__FB2U\\+ .style_ActiveSelectMatches__PEQ\\+Q {
  border-bottom: 2px solid #004C77;
}`, "",{"version":3,"sources":["webpack://./src/pages/match-page/modeWidgets/modePlayers/matchesWidget/selectMatchesButtons/style.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACI,kBAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,eAAA;EACA,qCAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;EACA,SAAA;AACR;AACI;EACI,gCAAA;AACR","sourcesContent":[".selectMatches {\n    margin-left: 25px;\n    height: max-content;\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: start;\n    align-items: center;\n    gap: 20px;\n\n    >div {\n        width: max-content;\n        height: max-content;\n        user-select: none;\n        cursor: pointer;\n        font-family: 'Montserrat', sans-serif;\n        font-size: 16px;\n        color: #004C77;\n        font-weight: 800;\n        border: 0\n    }\n    .ActiveSelectMatches {\n        border-bottom: 2px solid #004C77;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectMatches": `style_selectMatches__FB2U+`,
	"ActiveSelectMatches": `style_ActiveSelectMatches__PEQ+Q`
};
export default ___CSS_LOADER_EXPORT___;
