import style from "./styles.module.scss"
import React, { useCallback, useEffect, useState } from "react"
import avatar from 'images/avatar.svg'
import logo from "images/logo.png"
import { useIntl } from "react-intl"
import { apiProvider } from "app/api/api-provider"
import { TPlayerRating } from "app/api/types"
import { CountryBar } from "features/tournament-table/tournament-line/ui/country-bar/CountryBar"
import { useNavigate } from "react-router-dom"
import { Select } from "widgets/select/Select"
const api = apiProvider

export const RatingPage = (): JSX.Element => {
    const [players, setPlayers] = useState<TPlayerRating[]>([])
    const [count, setCount] = useState<number>(100)
    const [country, setCountry] = useState<string>('')
    const [countries, setCountries] = useState<any>([])
    const navigate = useNavigate()
    const intl = useIntl()

    useEffect(() => {
        getCountry()
    }, [])

    useEffect(() => {
        getPlayers(count, country)
    }, [count, country])

    const sortObjectByKeys = (obj: any) => {
        const reObj = Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [value, key])
        )
        return Object.keys(reObj)
            .sort() 
            .reduce((acc: any, key) => { 
                acc[key] = reObj[key];
                return acc;
            }, {});
    };

    const getCountry = useCallback(async () => {
        const res = await api.getCountry()
        if (!res || !res.country) {
            setCountries([])
            return
        }
        setCountries(sortObjectByKeys(res.country.country))
    }, [setCountries])

    const getPlayers = useCallback(async (count: number, country: string) => {
        const res = await api.getPlayersRatingData({page: 0, count: count, country: country})
        if (!res || !res.players) {
            setPlayers([])
            return
        }
        setPlayers(res.players)
    }, [setPlayers])

    const ratingTrim = (rating: number) => {
        const ratingStr = String(rating);
        const decimalIndex = ratingStr.indexOf(".");
        
        if (decimalIndex === -1) {
            return rating;
        }

        const integerPart = ratingStr.slice(0, decimalIndex);
        const decimalPart = ratingStr.slice(decimalIndex + 1);

        const trimmedDecimalPart = decimalPart.slice(0, 3);

        const trimmedRatingStr = `${integerPart}.${trimmedDecimalPart}`;

        return Number(trimmedRatingStr);
    }

    return (
        <div className={style.All}>
            <div className={style.SortPanel}>
                <div className={style.Logo}>
                    <img src={logo} alt="No"/>
                    <div>
                        <div>ATT Tour</div>
                        <div>{intl.formatMessage({id: "ratingPlayer"})}</div>
                    </div>
                </div>
                <div className={style.Selectors}>
                    <Select 
                        defaultValue={`${intl.formatMessage({id: "top"})} 100`}
                        selects={{
                            [`${intl.formatMessage({id: "top"})} 10`]: "10",
                            [`${intl.formatMessage({id: "top"})} 50`]: "50",
                            [`${intl.formatMessage({id: "top"})} 100`]: "100",
                            [`${intl.formatMessage({id: "top"})} 150`]: "150",
                            [`${intl.formatMessage({id: "top"})} 200`]: "200",
                        }}
                        onChange={(value: string) => setCount(Number(value))}/>
                    <Select
                        stylePage={{width: 250}}
                        defaultValue={`${intl.formatMessage({id: "allCountries"})}`}
                        selects={{
                            [`${intl.formatMessage({id: "allCountries"})}`]: "",
                            ...countries
                        }}
                        onChange={(value: string) => {setCountry(value)}}/>
                </div>
            </div>

            <div className={style.playersTable}>
                <div className={style.prev}>
                    <div>{'№'}</div>
                    <div>{intl.formatMessage({id: "sportsman"})}</div>
                    <div>{intl.formatMessage({id: "score"})}</div>
                </div>
                {players.map((player: TPlayerRating, index: number) => <div>
                    <div>{index + 1}</div>
                    <div className={style.Player}>
                        <div onClick={() => navigate(`/players/${player.id}`)}>
                            <img alt={"no"} src={player.avatar || avatar}/>
                            {player.country && <CountryBar countryName={''} flag={player.country} />}
                            <div>{player.name}</div>
                        </div>
                    </div>
                    <div>{ratingTrim(player.rating)}</div>
                </div>)}

            </div>

        </div>
    )
}