import React from "react"
import styles from "./styles.module.scss"
import { MatchBeatsWidget } from "pages/grid-page/UI/MatchBeatsWidget/MatchBeatsWidget"
import { FormattedMessage } from "react-intl"
import { VideoPlayer } from "widgets/videoPlayer/videoPlayer"

const MATCH_STATUS_WAIT = 1
const MATCH_STATUS_PLAYED = 2
const MATCH_STATUS_PAUSE = 3
const MATCH_STATUS_COMPLETE = 4

export const ModeMatch = ({
    matchData
}: any): JSX.Element => {
    return (
        <>
            <div className={styles.matchWidget}>
                {matchData?.matchbeats && <MatchBeatsWidget beats={matchData.matchbeats} autoScroll={true /*online*/} />}
            </div>
            <div className={styles.videoWrapper}>
                {matchData?.status === MATCH_STATUS_PLAYED && matchData.operatorId && <VideoPlayer operatorId={matchData.operatorId} />}
                {matchData?.status === MATCH_STATUS_COMPLETE && <div className={styles.OfflineBanner}>
                    <FormattedMessage id={'endMatch'}/>
                </div>}
                {matchData?.status === MATCH_STATUS_WAIT && <div className={styles.OfflineBanner}>
                    <FormattedMessage id={'expectedMatch'}/>
                </div>}
                {matchData?.status === MATCH_STATUS_PAUSE && <div className={styles.OfflineBanner}>
                    <FormattedMessage id={'suspendedMatch'}/>
                </div>}
            </div>
        </>
    )
}