// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_LiveStreamSign__yVW2d{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1px;
    background: #D81C1C;
    width: 31px;
    height: 12.4px;
    color: #FFF;
    font-size: 8px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/features/tournament-table/tournament-mobile/ui/live-sign/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX,cAAc;IACd,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".LiveStreamSign{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 1px;\n    background: #D81C1C;\n    width: 31px;\n    height: 12.4px;\n    color: #FFF;\n    font-size: 8px;\n    font-style: normal;\n    font-weight: 300;\n    line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LiveStreamSign": `styles_LiveStreamSign__yVW2d`
};
export default ___CSS_LOADER_EXPORT___;
