// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_logo__5UVHz {
  display: flex;
  position: relative;
  width: 75px;
}
.styles_logo__text__ptv56 {
  position: absolute;
  top: 9px;
  left: 42px;
  font-family: Montserrat, sans-serif;
  font-style: italic;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/shared/logo/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,WAAA;AACF;AACE;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,mCAAA;EACA,kBAAA;EACA,WAAA;AACJ","sourcesContent":[".logo {\n  display: flex;\n  position: relative;\n  width: 75px;\n\n  &__text {\n    position: absolute;\n    top: 9px;\n    left: 42px;\n    font-family: Montserrat, sans-serif;\n    font-style: italic;\n    color: #fff;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `styles_logo__5UVHz`,
	"logo__text": `styles_logo__text__ptv56`
};
export default ___CSS_LOADER_EXPORT___;
