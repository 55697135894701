import React, { useState } from "react";
import style from './style.module.scss'
import { FormattedMessage } from "react-intl";

type TArgSelectСoverageButtons = {
    setSelectСoverage: React.Dispatch<React.SetStateAction<number>>,
    selectСoverage: number
}

export const SelectСoverageButtons = ({
    setSelectСoverage,
    selectСoverage
}: TArgSelectСoverageButtons): JSX.Element => {

    return (
        <>
            <div className={style.selectСoverage}>
                <div className={selectСoverage === 0 ? style.ActiveSelectСoverage : ''} onClick={() => setSelectСoverage(0)}>
                    <FormattedMessage id={'allCoatings'}/>
                </div>
                <div className={selectСoverage === 1 ? style.ActiveSelectСoverage : ''} onClick={() => setSelectСoverage(1)}>
                    <FormattedMessage id={'ground'}/>
                </div>
                <div className={selectСoverage === 2 ? style.ActiveSelectСoverage : ''} onClick={() => setSelectСoverage(2)}>
                    <FormattedMessage id={'grass'}/>
                </div>
                <div className={selectСoverage === 3 ? style.ActiveSelectСoverage : ''} onClick={() => setSelectСoverage(3)}>
                    <FormattedMessage id={'hard'}/>
                </div>
            </div>
        </>
    )
}  