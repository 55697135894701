// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_All__iV5aG {
  margin-top: 23px;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.style_Tour__5oPZv {
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.style_tourName__Sl7Q9 {
  width: 100%;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(229, 239, 245, 0.9333333333);
}
.style_tourName__Sl7Q9 > div {
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.style_tourName__Sl7Q9 > div > div:nth-child(2) {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #00314D;
}
.style_tourName__Sl7Q9 img {
  height: 17px;
  width: 24px;
  border: 1px solid #297FB0;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/pages/Player-page/modePage/results/style.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mDAAA;AACJ;AACI;EACI,yBAAA;UAAA,iBAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACR;AACQ;EACI,qCAAA;EACA,eAAA;EACA,cAAA;AACZ;AAGI;EACI,YAAA;EACA,WAAA;EACA,yBAAA;EACA,iBAAA;AADR","sourcesContent":[".All {\n    margin-top: 23px;\n    height: max-content;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: center;\n}\n\n.Tour {\n    height: max-content;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: start;\n    align-items: center;\n}\n\n.tourName {\n    width: 100%;\n    height: 72px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    background-color: #e5eff5ee;\n\n    >div {\n        user-select: none;\n        cursor: pointer;\n        display: flex;\n        flex-direction: row;\n        justify-content: center;\n        align-items: center;\n\n        >div:nth-child(2) {\n            font-family: 'Montserrat', sans-serif;\n            font-size: 14px;\n            color: #00314D;\n        }\n    }\n\n    img {\n        height: 17px;\n        width: 24px;\n        border: 1px solid #297FB0;\n        object-fit: cover;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"All": `style_All__iV5aG`,
	"Tour": `style_Tour__5oPZv`,
	"tourName": `style_tourName__Sl7Q9`
};
export default ___CSS_LOADER_EXPORT___;
