import styles from './styles.module.css';

export interface IWinSignArgs {
    showState: boolean;
}

export function WinSign({showState}: IWinSignArgs): JSX.Element {
    if(!showState){
        return <></>
    }
    return <div className={styles.WinSign}>
        {showState && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_10_2764)">
                <path d="M19.1421 8.41431L9.9497 17.6067L8.53549 16.1925L17.7279 7.00009L19.1421 8.41431Z" fill="#008F70"/>
                <path d="M6.41418 11.2427L11.3639 16.1924L9.94972 17.6066L4.99997 12.6569L6.41418 11.2427Z" fill="#008F70"/>
            </g>
            <defs>
                <clipPath id="clip0_10_2764">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>}
    </div>
}