import styles from './styles.module.scss';
import {Tab} from "./tab";
import {ITabsProps} from "./types";

export function Tabs({tabs, tabChanger}: ITabsProps) {
    const tabsElements = tabs
        .map((tab: any, index: number) => (<Tab key={index} tabChanger={tabChanger(index)}>{tab}</Tab>));

    return <div className={styles.tabs}>
        {tabsElements}
    </div>
}