import React from "react";
import {TournamentGrid} from "../../widgets/tournament-grid/TournamentGrid";



export function TournamentPage(){
    return <>
        <div className={'Total'}>
            <TournamentGrid />
        </div>
    </>
}         