import React from "react";
import style from './style.module.scss'
import { FormattedMessage } from "react-intl";

type TArgSelectMatchesButtons = {
    setSelectMatches: React.Dispatch<React.SetStateAction<number>>,
    selectMatches: number
}

export const SelectMatchesButtons = ({
    setSelectMatches,
    selectMatches
}: TArgSelectMatchesButtons): JSX.Element => {

    return (
        <>
            <div className={style.selectMatches}>
                <div className={selectMatches === 0 ? style.ActiveSelectMatches : ''} onClick={() => setSelectMatches(0)}>
                    <FormattedMessage id={'H2H'}/>
                </div>
                <div className={selectMatches === 1 ? style.ActiveSelectMatches : ''} onClick={() => setSelectMatches(1)}>
                    <FormattedMessage id={'face-to-faceMeetings'}/>
                </div>
            </div>
        </>
    )
}  