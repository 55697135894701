import styles from './styles.module.scss'
import {ReactComponent as TelegramIcon} from 'asserts/social/telegram.svg';
import {ReactComponent as VkIcon} from 'asserts/social/vk.svg';
import {ReactComponent as FbIcon} from 'asserts/social/fb.svg';
import {Logo} from "shared/logo";
import { FormattedMessage } from 'react-intl';

export function Footer() {
    return <div className={styles.footer}>
        <div className={styles.footer__content}>
            <div className={styles.footer__menu}>
                <div>
                    <Logo />
                </div>
                <div className={styles.footer__menu_list}>
                    <a><FormattedMessage id={'legalInformation'}/></a>
                    <a><FormattedMessage id={'contacts'}/></a>
                    <a><FormattedMessage id={'advertising'}/></a>
                    <a><FormattedMessage id={'privacyPolicy'}/></a>
                    <a><FormattedMessage id={'termsOfUse'}/></a>
                </div>
            </div>
            <div className={styles.footer__menu_images}>
                <a href='https://t.me/amateurtennistour' target="_blank">
                    <TelegramIcon/>
                </a>
                <a href='https://vk.com/amateurtennistour2024' target="_blank">
                    <VkIcon/>
                </a>
                <a href='https://www.facebook.com/profile.php?id=61557477106429&mibextid=LQQJ4d' target="_blank">
                    <FbIcon/>
                </a>
            </div>
        </div>
    </div>
}