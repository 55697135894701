import { TGridParams, TMatchGridPoints, TTourGridPoints } from "./types"
import {
    GRID_FIRST_BLOCK_HEIGHT,
    GRID_FIRST_BLOCK_LEFT_OFFSET,
    GRID_FIRST_BLOCK_TOP_OFFSET,
    GRID_MATCH_BLOCK_SIZE,
    GRID_COLS_OFFSET,
} from "./constants"

export const generateGridPoints = (tournament: any) => {

    if (!tournament.length) {
        return null
    }

    const firstTour = tournament[0]

    const startGridPoints: TTourGridPoints = []

    firstTour.matches.forEach((_: any, id: number) => {
        if (id === 0) {
            startGridPoints.push({
                x: {
                    start: GRID_FIRST_BLOCK_LEFT_OFFSET,
                    stop: GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE,
                },
                y: {
                    start: GRID_FIRST_BLOCK_TOP_OFFSET,
                    stop: GRID_FIRST_BLOCK_TOP_OFFSET,
                }
            })
            return
        }

        const previous = startGridPoints.at(-1)

        if (!previous) {
            throw new Error('<Logic Error>: Previous is empty')
        }

        startGridPoints.push({
            x: {
                start: GRID_FIRST_BLOCK_LEFT_OFFSET,
                stop: GRID_FIRST_BLOCK_LEFT_OFFSET + GRID_MATCH_BLOCK_SIZE,
            },
            y: {
                start: previous.y.start + GRID_FIRST_BLOCK_TOP_OFFSET,
                stop: previous.y.start + GRID_FIRST_BLOCK_TOP_OFFSET,
            }
        })
    })

    const connections: TMatchGridPoints[] = []


    for (let i = 0; i < startGridPoints.length; i += 2) {
        connections.push({
            x: {
                start: startGridPoints[i].x.stop,
                stop: startGridPoints[i + 1].x.stop,
            },
            y: {
                start: startGridPoints[i].y.stop,
                stop: startGridPoints[i + 1].y.stop,
            },
        })
    }

    return {
        startGridPoints,
        connections,
    }
}


export const generateWholeGridPoints = (tournament: any) => {
    const gridPoints: TGridParams | null = generateGridPoints(tournament)

    if (!gridPoints) {
        return null
    }

    let {
        startGridPoints,
        connections,
    } = gridPoints

    let nextNetworkElementsSize = startGridPoints.length

    let otherConnections: TTourGridPoints = []
    let otherLines: TTourGridPoints = []
    let currentConnections = connections

    const toDraw: any = [
        {
            col: 0,
            lines: startGridPoints,
            connections,
        }
    ]

    let toDrawId = 0

    while (nextNetworkElementsSize !== 1) {

        toDrawId += 1

        for (let i = 0; i < currentConnections.length; i += 2) {
            const c1 = currentConnections[i]
            const c2 = currentConnections[i + 1]

            if(!c2){
                otherLines.push({
                    x: {
                        start: c1.x.stop,
                        stop: c1.x.stop + GRID_COLS_OFFSET + GRID_MATCH_BLOCK_SIZE,
                    },
                    y: {
                        start: c1.y.start + Math.ceil((c1.y.stop - c1.y.start) / 2),
                        stop: c1.y.start + Math.ceil((c1.y.stop - c1.y.start) / 2),
                    }
                })
                continue
            }

            otherLines.push({
                x: {
                    start: c1.x.stop,
                    stop: c1.x.stop + GRID_COLS_OFFSET + GRID_MATCH_BLOCK_SIZE,
                },
                y: {
                    start: c1.y.start + Math.ceil((c1.y.stop - c1.y.start) / 2),
                    stop: c1.y.start + Math.ceil((c1.y.stop - c1.y.start) / 2),
                }
            })

            otherLines.push({
                x: {
                    start: c1.x.stop,
                    stop: c1.x.stop + GRID_COLS_OFFSET + GRID_MATCH_BLOCK_SIZE,
                },
                y: {
                    start: c2.y.start + Math.ceil((c2.y.stop - c2.y.start) / 2),
                    stop: c2.y.start + Math.ceil((c2.y.stop - c2.y.start) / 2),
                }
            })

            otherConnections.push({
                x: {
                    start: c1.x.stop + GRID_COLS_OFFSET + GRID_MATCH_BLOCK_SIZE,
                    stop: c1.x.stop + GRID_COLS_OFFSET + GRID_MATCH_BLOCK_SIZE,
                },
                y: {
                    start: c1.y.start + Math.ceil((c1.y.stop - c1.y.start) / 2),
                    stop: c2.y.start + Math.ceil((c2.y.stop - c2.y.start) / 2),
                }
            })

        }

        toDraw[toDrawId] = {
            lines: [...otherLines],
            connections: [...otherConnections],
            col: toDrawId,
        }

        // connections = [...connections, ...otherConnections]
        currentConnections = [...otherConnections]
        otherConnections = []
        otherLines = []

        nextNetworkElementsSize /= 2
    }

    return [
        ...toDraw
    ]



}