import styles from "./styles.module.scss";
import {Tour} from "./Tour/Tour";
import React, {useState} from "react";
import {ReactComponent as NavigationButton} from './icons/NavigationButton.svg';
import {MobileTournament} from "../mobile/MobileTournament";
import { useIntl } from "react-intl";


export function Schedule({ tournament, tournamentName }: any){
    const intl = useIntl()

    const pageTours = 3;

    const [page, setPage] = useState(0);

    const incrementPage = () => {
        if (tournament.length === pageTours) {
            return;
        }

        if ((tournament.length - (page + 1) * pageTours) <= -pageTours) {
            return;
        }

        setPage(page + 1);
    }

    const decrementPage = () => {
        if (page <= 0) {
            setPage(0)
            return;
        }

        setPage(page - 1);
    }

    if (!tournament) {
        return null;
    }

    if(!tournament.isEllimination && tournament.isEllimination !== undefined){

        return <>
            <div className={styles.schedule}>
            {page !== 0 && <NavigationButton className={styles.left} onClick={decrementPage}/>}
            {page !== 1 && <NavigationButton className={styles.right} onClick={incrementPage}/>}
            {
                page === 0 && <>
                    <div className={styles.tours}>
                        <Tour tour={tournament.upperGrid[0].matches} tourName={intl.formatMessage({id: "firstTour"}) + intl.formatMessage({id: "upperMesh"})} tourId={0}/>
                        <Tour tour={tournament.bottomGrid[0]} tourName={intl.formatMessage({id: "firstTour"}) + intl.formatMessage({id: "bottomMesh"})} tourId={0}/>
                        <Tour tour={[tournament.forSeventPlace]} tourName={intl.formatMessage({id: "for7thPlace"})} />
                    </div>    
                    <div className={styles.tours}>
                        <Tour tour={tournament.upperGrid[1].matches} tourName={intl.formatMessage({id: "secondTour"}) + intl.formatMessage({id: "upperMesh"})} />
                        <Tour tour={tournament.bottomGrid[1]} tourName={intl.formatMessage({id: "secondTour"}) + intl.formatMessage({id: "bottomMesh"})} />
                        <Tour tour={[tournament.forFifthPlace]} tourName={intl.formatMessage({id: "for5thPlace"})} />
                    </div>
                </>
            }
            {
                page === 1 && <>
                    <div className={styles.tours}>
                        <Tour tour={tournament.upperGrid[2].matches} tourName={intl.formatMessage({id: "thirdTour"}) + intl.formatMessage({id: "upperMesh"})} />
                        <Tour tour={tournament.bottomGrid[2]} tourName={intl.formatMessage({id: "thirdTour"}) + intl.formatMessage({id: "bottomMesh"})} />
                    </div>
                    <div className={styles.tours}>
                        <Tour tour={[tournament.hemifinal]} tourName={intl.formatMessage({id: "semi-final"})} />
                        <Tour tour={[tournament.final]} tourName={intl.formatMessage({id: "theFinal"})} />
                    </div>
                </>
            }
            </div>
        </>

        return (<div className={styles.schedule}>
            {page !== 0 && <NavigationButton className={styles.left} onClick={decrementPage}/>}
            <NavigationButton className={styles.right} onClick={incrementPage}/>
            <div className={styles.tours}>
                {tournament.upperGrid.slice(page, page + pageTours).map((tour: any, tid: number) => {
                    return <Tour key={tid} tour={tour.matches} />
                })}
                {tournament.bottomGrid.slice(page, page + pageTours).map((tour: any, tid: number) => {
                    return <Tour key={tid} tour={tour} />
                })}
            </div>
            {/* <div className={styles.mobileTournament}>
                <MobileTournament tournament={tournament} tournamentName={tournamentName} isSchedule/>
            </div> */}
        </div>);
    }

    return (<div className={styles.schedule}>
        {page !== 0 && <NavigationButton className={styles.left} onClick={decrementPage}/>}
        <NavigationButton className={styles.right} onClick={incrementPage}/>
        <div className={styles.tours}>
            {tournament.slice(page, page + pageTours).map((tour: any, tid: number) => {
                return <Tour key={tid} tour={tour.matches} tourId={tid} tourName={tour.name} />
            })}
        </div>
        <div className={styles.mobileTournament}>
            <MobileTournament tournament={tournament} tournamentName={tournamentName} isSchedule/>
        </div>
    </div>);
}
