import styles from "./styles.module.css";
import React, {useState} from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

export function Block2({news}: any) {
    const intl = useIntl()
    const local = useSelector((state: any) => state.localLanguage.language)
    const [activeState, setActiveState] = useState(false);
    const toggleClass = () => setActiveState(!activeState);

    return(
        <div className={styles.contBody}>
            <div>{local === 'ru' ? news.headers1 : news.headers1EN}</div>
            {news.banner && <img src={news.banner} alt={'error image'}/>}
            <div>{local === 'ru' ? news.headers2 : news.headers2EN}</div>
            <span className={activeState ? classNames(styles.textBody, styles.textBodyAdd) : styles.textBody}>{local === 'ru' ? news.text : news.textEN}</span>
            <div className={styles.contButt}>
                <button onClick={toggleClass} className={styles.butt2}>{activeState ? intl.formatMessage({id: "hide"}) : intl.formatMessage({id: "expandCompletely"})}</button>
            </div>
        </div>
    );
}

export default Block2;