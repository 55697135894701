// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("app/styles/fonts/src/Lato-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("app/styles/fonts/src/Lato-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("app/styles/fonts/src/Montserrat-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("app/styles/fonts/src/Montserrat-Light.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Lato";
  font-weight: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

@font-face {
  font-family: "Lato";
  font-weight: 300;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("truetype");
}
`, "",{"version":3,"sources":["webpack://./src/app/styles/fonts/fonts.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,+DAAmE;AACrE;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,+DAAkE;AACpE;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,+DAAyE;AAC3E;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,+DAAwE;AAC1E","sourcesContent":["@font-face {\n  font-family: \"Lato\";\n  font-weight: normal;\n  src: url(\"app/styles/fonts/src/Lato-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Lato\";\n  font-weight: 300;\n  src: url(\"app/styles/fonts/src/Lato-Light.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Montserrat\";\n  font-weight: normal;\n  src: url(\"app/styles/fonts/src/Montserrat-Medium.ttf\") format(\"truetype\");\n}\n\n@font-face {\n  font-family: \"Montserrat\";\n  font-weight: 300;\n  src: url(\"app/styles/fonts/src/Montserrat-Light.ttf\") format(\"truetype\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
