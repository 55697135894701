import styles from './styles.module.scss';
import {SearchBar} from "../../features/search-bar";
import SearchBarMobile from "../../features/search-bar-mobile/SearchBarMobile";
import React, {useRef, useState} from "react";
import {Logo} from "../logo/logo";
import {ApiProvider} from "../../app/api/api-provider";
import {Link} from "react-router-dom";
import {debounce} from "../helpers";
import _ from "lodash";
import { Language } from 'shared/language/language';



export function Header(){

    const api = ApiProvider();

    const [tournaments, setTournaments] = useState([]);

    const [search, setSearch] = useState('');

    const setServicesValueDebounced = useRef(_.debounce(searchHandler, 1000));

    const onChangeInputHandler = (evt: any) => {
        const { value } = evt.target;
        setSearch(value)

        setServicesValueDebounced.current(value)
    }

    function searchHandler(value: string) {
        if (!value) {
            setTournaments([]);
            return;
        }

        return api.getTournamentsData({name: value}).then((res) => {
            setTournaments(res.tournaments?.map((tournament: any) => {
                const {name, id} = tournament
                return {
                    name, id
                }
            }) || [])
        })
    }

    const clearSearch = () => {
        setSearch('');
        setTournaments([]);
    }


    return <div className={styles.Header}>
        <Logo/>
        <div className={styles.searches}>
            {/*<SearchBar onChangeInputHandler={onChangeInputHandler} search={search}/>*/}
            {/*<SearchBarMobile onChangeInputHandler={onChangeInputHandler} search={search}/>*/}
            {tournaments.length > 0 && <div className={styles.autocomplete}>
                <div className={styles.options}>
                    {tournaments.map((tournament: any) => {
                        return <div key={tournament.id} className={styles.option}>
                            <Link to={`/tournaments?name=${tournament.id}`} onClick={() => clearSearch()}>
                                {tournament.name}
                            </Link>
                        </div>
                    })}
                </div>
            </div>}
        </div>
        <Language/>
    </div>

}
