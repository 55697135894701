import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss'
import React, { useEffect, useState } from 'react';
import { setLanguage } from 'app/store/slices/locale-language-slice';


export const Language = () => {
    const dispatch = useDispatch()
    const [activateMenu, setActivateMenu] = useState<boolean>(false)
    const language = useSelector((state: any) => state.localLanguage.language)
    type TLanguages = {
        [key: string]: string;
    }
    const languages: TLanguages = {
        'ru': 'RU',
        'en': 'EN'
    }

    const languageСhange = (language: string) => {
        setActivateMenu(!activateMenu)
        dispatch(setLanguage(language))
    }

    return <div className={styles.app}>
        <div className={styles.language}>{languages[language]}</div>
        <button className={styles.burger} onClick={() => setActivateMenu(!activateMenu)}>
            <div className={!activateMenu? styles.line : styles.line + ' ' + styles.lineActive}></div>
            <div className={!activateMenu? styles.line : styles.line + ' ' + styles.lineActive}></div>
        </button>
        {activateMenu && <div className={styles.selected}>
            <div onClick={() => languageСhange('ru')}>RU</div>    
            <div onClick={() => languageСhange('en')}>EN</div>    
        </div>}
    </div>
}