import {ECourtTypeEnum} from "../../features/tournament-table/tournament-line/types";
import {ETournamentType} from "../../features/tournament-table/table-tab/types";


/**
 * 1) Данные кнопок заголовка таблицы
 * 2) Данные получаемые при выбранной категории
 * 3) Данные при нажатии на кнопку "показать еще"
 */

export const tournamentTableInputData = {
    types: [
        {
            name: 'Мужчины',
            type: 'men',
            group: 'World Tennis Tour',
            tournamentType: ETournamentType.world,
        },
        {
            name: 'Женщины',
            type: 'women',
            group: 'World Tennis Tour',
            tournamentType: ETournamentType.world,
        },
        {
            name: 'Джуниоры',
            type: 'juniors',
            group: 'World Tennis Tour',
            tournamentType: ETournamentType.masters,
        },
        {
            name: 'Мастерс',
            type: 'masters',
            group: 'Masters Tour',
            tournamentType: ETournamentType.wheelchair,
        },
    ]
}


export const menContent = {
    data: [
        {
            name: 'Мужчины. Россия. Третий дивизион',
            date: new Date().toDateString(),
            id: '0',
            coverage: {
                name: 'Терафлекс',
                type: ECourtTypeEnum.hard,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: true,
        },
        {
            name: 'Мужчины. Россия. Четвертый дивизион',
            date: new Date().toDateString(),
            id: '1',
            coverage: {
                name: 'Открытый. Твердый',
                type: ECourtTypeEnum.clay,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,
        },
        {
            name: 'Мужчины. Россия. Пятый дивизион',
            date: new Date().toDateString(),
            id: '2',
            coverage: {
                name: 'Открытый. Мягкий',
                type: ECourtTypeEnum.grass,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: true,
        },
        {
            name: 'Мужчины. Россия. Шестой дивизион',
            date: new Date().toDateString(),
            id: '3',
            coverage: {
                name: 'Терафлекс',
                type: ECourtTypeEnum.beach,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,

        },
        {
            name: 'Мужчины. Россия. Седьмой дивизион',
            date: new Date().toDateString(),
            id: '4',
            coverage: {
                name: 'Открытый. Твердый',
                type: ECourtTypeEnum.clay,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,
        },
        {
            name: 'Мужчины. Россия. Восьмой дивизион',
            date: new Date().toDateString(),
            id: '5',
            coverage: {
                name: 'Открытый. Мягкий',
                type: ECourtTypeEnum.clay,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,
        },
        {
            name: 'Мужчины. Россия. Девятый',
            date: new Date().toDateString(),
            id: '5',
            coverage: {
                name: 'Открытый. Мягкий',
                type: ECourtTypeEnum.clay,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,
        },
        {
            name: 'Мужчины. Россия. Третий дивизион',
            date: new Date().toDateString(),
            id: '0',
            coverage: {
                name: 'Терафлекс',
                type: ECourtTypeEnum.hard,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: true,
        },
        {
            name: 'Мужчины. Россия. Четвертый дивизион',
            date: new Date().toDateString(),
            id: '1',
            coverage: {
                name: 'Открытый. Твердый',
                type: ECourtTypeEnum.clay,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,
        },
        {
            name: 'Мужчины. Россия. Пятый дивизион',
            date: new Date().toDateString(),
            id: '2',
            coverage: {
                name: 'Открытый. Мягкий',
                type: ECourtTypeEnum.grass,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: true,
        },
        {
            name: 'Мужчины. Россия. Шестой дивизион',
            date: new Date().toDateString(),
            id: '3',
            coverage: {
                name: 'Терафлекс',
                type: ECourtTypeEnum.beach,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,

        },
        {
            name: 'Мужчины. Россия. Седьмой дивизион',
            date: new Date().toDateString(),
            id: '4',
            coverage: {
                name: 'Открытый. Твердый',
                type: ECourtTypeEnum.clay,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,
        },
        {
            name: 'Мужчины. Россия. Восьмой дивизион',
            date: new Date().toDateString(),
            id: '5',
            coverage: {
                name: 'Открытый. Мягкий',
                type: ECourtTypeEnum.clay,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,
        },
        {
            name: 'Мужчины. Россия. Девятый',
            date: new Date().toDateString(),
            id: '5',
            coverage: {
                name: 'Открытый. Мягкий',
                type: ECourtTypeEnum.clay,
            },
            country: {
                countryName: 'Россия',
                flag: 'ru',
            },
            live: false,
        },
    ]
};
