import './TournamentCompletePage.scss';
import {TournamentsTable} from "widgets/tournaments-table";
import styles from './styles.module.scss';


/**
 * Тут должна происходить инициализация всех структур данных главной страницы
 * 
 * 1) Список промо-новостей
 * 2) Полный список турниров, которые должен отобразить сайт в виду требований к индексации поисковыми системами
 * 3) Требуется перевести сайт на SSR
 * 
 * Влияние SSR
 * 1) Вместо провайдеров будет передача данных через пропсы
 * 2) Вместо API будет прямое подключение к БД
 * 
 * Следовательно завершение разработки фронтенда должно быть выполнено на моках, 
 * либо на замещающих объектах построенных на апи провайдераъ
 * @returns 
 */

function TournamentCompletePage() {
  return (
      <>
          <div className={styles.MainContentBlock}>
              <TournamentsTable live={'complete'}/>
          </div>
          <div className={'background-gradient'}/>
      </>
  );
}

export default TournamentCompletePage;
