import styles from './styles.module.scss';
import {ITournamenLineArgs} from "./types";
import {CountryBar} from "./ui/country-bar/CountryBar";
import {CourtTypeBar} from "./ui/court-type-bar/CourtTypeBar";
import {LiveStreamSign} from "./ui/live-sign/LiveStreamSign";
import {Link, useNavigate} from "react-router-dom";
export function TournamentLine({
    name,
    country,
    courtType,
    live,
    tournamentId,
    dateOf,
    startDate,
    completeDate,
    id}: ITournamenLineArgs){


    return <div className={styles.TournamentLine}>
        <div>
            <Link className={styles.Name} to={`/tournaments/${id}`}>{name}</Link>
            <div className={styles.mobileLive}>
                {live && <div><LiveStreamSign/></div>}
            </div>
        </div>
        <div className={styles.countryCourt}>
            <div className={styles.Country}>
                <CountryBar {...country} />
            </div>
            <div className={styles.CourtType}>
                <CourtTypeBar {...courtType} />
            </div>
        </div>
        <div>
            {live && <div><LiveStreamSign/></div>}
        </div>
        <div>
            {startDate} - {completeDate}
        </div>
    </div>
}
