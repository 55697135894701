import axios from "axios";
import {pageLimit} from "../../widgets/tournaments-table/consts";

export function ApiProvider(){
    const getTournamentsData = async (
        {
            page = 0,
            type  = '',
            tournament = [],
            name = '',
            live = ''
        }: any) => {
        return axios.get('/api/tournament', {
            params: {
                count: pageLimit,
                page,
                gamers: `^${type}$`,
                name,
                live
            }
        }).then((res) => {
            return res.data
        });
    }

    const getPlayersData = async ({name, page, count = 10} : any) => {
        return axios.get('/api/player', {
            params: {
                name,
                page,
                count
            }
        }).then((res) => {
            return res.data
        })
    }

    const getPlayerData = async ({id}: any) => {
        return axios.get(`/api/player/${id}`)
        .then((res) => {
            return res.data
        })
    }

    const getTourMatchPlayerData = async (playerId: string) => {
        return axios.get('/api/tournament', {
            params: {
                internalPlayers: playerId,
            }
        }).then((res) => {
            return res.data
        })
    }

    const getNewsData = async (
        {
            page,
            count = 9
        } : any) => {
        return axios.get('/api/news', {
            params: {
                page,
                count
            }
        }).then((res) => {
            return res.data
        })
    }

    const takeMatchData = async (tournamentId: string, tourId: string, matchId: string) => {
        try{
            const response = await axios.get(`/api/match/tournament/${tournamentId}/tour/${tourId}/match/${matchId}`)
            return response.data

        }catch(e: unknown){
            console.error(e)
        }
    }

    const takeMatchById = async (matchId: string) => {
        try{
            const response = await axios.get(`/api/match/${matchId}`)
            return response.data
        }catch(e: unknown){
            console.error(e)
        }
    }

    return {
        getTourMatchPlayerData,
        getTournamentsData,
        getPlayersData,
        getPlayerData,
        getNewsData,
        takeMatchData,
        takeMatchById,
    }
}

export const apiProvider = ApiProvider()