export enum ETournamentType {
    masters = 'masters',
    world = 'world',
    wheelchair = 'wheelchair',
    beach = 'beach',
}


export interface ITableTabArgs {
    name: string;
    onClick: () => void;
    className?: string;
    tournamentType: ETournamentType;
    isActive?: boolean;
}