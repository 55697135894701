// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_teams__OYjtk {
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
  color: #606060;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-top: 1px dashed #297FB0;
  border-bottom: 1px dashed #297FB0;
}`, "",{"version":3,"sources":["webpack://./src/entities/ui/statisticsTab/teams/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EAEA,8BAAA;EACA,iCAAA;AAAJ","sourcesContent":[".teams {\n    display: flex;\n    justify-content: space-between;\n    padding: 6px 16px;\n    color: #606060;\n    font-size: 10px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n\n    border-top: 1px dashed #297FB0;\n    border-bottom: 1px dashed #297FB0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"teams": `styles_teams__OYjtk`
};
export default ___CSS_LOADER_EXPORT___;
