import {ECourtTypeEnum, TCourtType} from "../../types";
import styles from '../../styles.module.scss';
import ClayIcon from './asserts/surface-clay.svg';
import BeachIcon from './asserts/surface-beach.svg';
import GrassIcon from './asserts/surface-grass.svg'
import HardIcon from './asserts/surface-hard.svg';
import { useIntl } from "react-intl";

const DEFAULT_COURT_TYPE = 'Покрытие'

export function CourtTypeBar({
        name,
        type,
    }: TCourtType){
    const intl = useIntl

    const courtBarTranslator: any = {
    grass: intl().formatMessage({id: 'grass'}),
    ground: intl().formatMessage({id: 'ground'}),
    hard: intl().formatMessage({id: 'hard'}),
    }

    const iconChoser = {
        [ECourtTypeEnum.clay]: ClayIcon,
        [ECourtTypeEnum.hard]: HardIcon,
        [ECourtTypeEnum.beach]: BeachIcon,
        [ECourtTypeEnum.grass]: GrassIcon,
    };

    const iconSrc = iconChoser[type];

    return <div className={styles.CourtTypeBar}>
        <div className={styles.CourtName}>{courtBarTranslator[name] || DEFAULT_COURT_TYPE}</div>
        <div className={styles.imageWrapper}>
            {iconSrc && <img src={iconSrc} alt={'Nothing showed'} /> }
        </div>
    </div>
}
