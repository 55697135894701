export enum EMatchStatises {
    WAIT = 1,
    PLAYED = 2,
    PAUSE = 3,
    COMPLETE = 4,
}

export const ELLIMINATION_TOURNAMENT_CARD = 0
export const TOP_GRID_CARD = 1
export const BOTTOM_GRID_CARD = 2
export const FOR_SEVENTH_PLACE_CARD = 3
export const FOR_FIFTH_PACE_CARD = 4
export const HEMIFINAL = 5
export const FINAL = 6


export const MATCH_STATUS_WAIT = 1
export const MATCH_STATUS_PLAYED = 2
export const MATCH_STATUS_PAUSE = 3
export const MATCH_STATUS_COMPLETE = 4