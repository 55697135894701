import React from "react";
import { IntlProvider } from 'react-intl';
import ru from '/app/src/i18n/ru-RU.json'
import en from '/app/src/i18n/en-US.json'
import { useSelector } from "react-redux";

const language: any = {
    "ru": ru,
    "en": en
}

export function InternationalizationProvider ({children}: any): JSX.Element {
    const selector = useSelector
    const local = selector((state: any) => state.localLanguage.language)
    console.log('language: ', local)

    return (
        <IntlProvider messages={language[local]} locale='ru' defaultLocale='ru'>
            {children}
        </IntlProvider>
    ) 
}